import shortUuid from 'short-uuid';
import _cloneDeep from 'lodash/cloneDeep';
import * as Sentry from '@sentry/react';
import { COMPONENT_TYPES } from 'src/app/TopListsMosaic/layout/components';
import { DEFAULT_TEMPLATE_ID } from 'src/app/ChartingLibrary/constants';
import { TRACKER_ACTIONS } from 'src/app/components/grid/topListScanner/dataSource/dataSource';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';
import {
  CB_ITEM_NAMES,
  FLAGS as NEWS_CAT_FLAGS
} from 'src/app/components/grid/topListNews/columns/newsConstants';
import {
  predefinedLayoutScannerColumnProfiles,
  predefinedLayoutScannerFilterProfiles,
  predefinedLayoutKeyStatsProfiles,
  defaultLayoutHistoryFilterProfiles,
  defaultLayoutHistoryColumnProfiles,
  defaultLayoutNewsColumnProfiles,
  predefinedLayoutHistoryAggregateProfiles,
  // defaultScannerExpressionProfiles
} from './topListLayoutProfileBases';
import { LinkingIcon, NoLinkingIcon } from 'src/theme/EdgeIcons';
import { alpha } from '@material-ui/core';


export const defaultNewsCategories = {
  [CB_ITEM_NAMES.PRESS_RELEASE]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.OTHER_NEWS]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.TOPICS]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.SEC_CATEGORIES]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
};

export const newsOnlyNewsCategories = {
  [CB_ITEM_NAMES.PRESS_RELEASE]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.OTHER_NEWS]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.TOPICS]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
  [CB_ITEM_NAMES.SEC_CATEGORIES]: { flag: NEWS_CAT_FLAGS.NONE, data: [] },
};

export const secOnlyNewsCategories = {
  [CB_ITEM_NAMES.PRESS_RELEASE]: { flag: NEWS_CAT_FLAGS.NONE, data: [] },
  [CB_ITEM_NAMES.OTHER_NEWS]: { flag: NEWS_CAT_FLAGS.NONE, data: [] },
  [CB_ITEM_NAMES.TOPICS]: { flag: NEWS_CAT_FLAGS.NONE, data: [] },
  [CB_ITEM_NAMES.SEC_CATEGORIES]: { flag: NEWS_CAT_FLAGS.ALL, data: [] },
};

export const WATCHLIST_MANUAL_ORDER = 'assigned'

/**
 * @typedef {Object} ColorItem
 * @property {string} color
 * @property {string} title
 * @property {React.Component} Icon
 */

/** @type {{string: ColorItem}} LINK_COLORS */
export const LINK_COLORS = {
  none: {
    color: alpha('#E6E8E5', .4),
    title: 'None',
    name: 'none',
    Icon: NoLinkingIcon
  },
  white: {
    color: '#E6E8E5',
    title: 'On Change',
    name: 'white',
    Icon: LinkingIcon
  },
  blue: {
    color: '#0FBBE0',
    title: 'Blue',
    name: 'blue',
    Icon: LinkingIcon
  },
  green: {
    color: '#1ED26F',
    title: 'Green',
    name: 'green',
    Icon: LinkingIcon
  },
  yellow: {
    color: '#FFE500',
    title: 'Yellow',
    name: 'yellow',
    Icon: LinkingIcon
  },
  red: {
    color: '#EF5350',
    title: 'Red',
    name: 'red',
    Icon: LinkingIcon
  }
};


export const PROFILE_TYPES = {
  FILTER: 'FILTER',
  COLUMN: 'COLUMN',
  WATCHLIST_ROWS: 'WATCHLIST_ROWS',
  KEY_STATS: 'KEY_STATS',
  AGGREGATE: 'AGGREGATE'
}


/**
 * Holds information about our various User profiles, and their default values
 *
 * @typedef {Object} ProfileConfigItem
 * @property {string} listKey - the name of the key in Profile state where the list of profiles is stored
 * @property {string} idKey   - the name of the key in Component state where the individual ID is stored
 * @property {Object[]} predefinedProfiles
 * @property {boolean} [nonLayoutState] - Don't process these profiles in layoutActions. Don't save to dynamo, don't add predefineds, don't strip predefineds.
 * @property {string} defaultProfileId
 **/


export const PROFILE_CONFIG = {
  HISTORY_FILTERS: {
    listKey: 'historyFilterProfiles',
    idKey: 'historyFilterProfileId',
    type: PROFILE_TYPES.FILTER,
    predefinedProfiles: defaultLayoutHistoryFilterProfiles,
    defaultProfileId: defaultLayoutHistoryFilterProfiles[0].id
  },
  HISTORY_COLUMNS: {
    listKey: 'historyColumnProfiles',
    idKey: 'historyColumnProfileId',
    type: PROFILE_TYPES.COLUMN,
    predefinedProfiles: defaultLayoutHistoryColumnProfiles,
    defaultProfileId: defaultLayoutHistoryColumnProfiles[0].id
  },
  HISTORY_AGGREGATES: {
    listKey: 'historyAggregateProfiles',
    idKey: 'historyAggregateProfileId',
    type: PROFILE_TYPES.AGGREGATE,
    predefinedProfiles: predefinedLayoutHistoryAggregateProfiles,
    defaultProfileId: predefinedLayoutHistoryAggregateProfiles[0].id
  },
  SCANNER_FILTERS: {
    listKey: 'scannerFilterProfiles',
    idKey: 'scannerFilterProfileId',
    type: PROFILE_TYPES.FILTER,
    predefinedProfiles: predefinedLayoutScannerFilterProfiles,
    defaultProfileId: predefinedLayoutScannerFilterProfiles[0].id
  },
  SCANNER_COLUMNS: {
    listKey: 'scannerColumnProfiles',
    idKey: 'scannerColumnProfileId',
    type: PROFILE_TYPES.COLUMN,
    predefinedProfiles: predefinedLayoutScannerColumnProfiles,
    defaultProfileId: predefinedLayoutScannerColumnProfiles[0].id
  },
  NEWS_COLUMNS: {
    listKey: 'newsColumnProfiles',
    idKey: 'newsColumnProfileId',
    type: PROFILE_TYPES.COLUMN,
    predefinedProfiles: defaultLayoutNewsColumnProfiles,
    defaultProfileId: defaultLayoutNewsColumnProfiles[0].id
  },
  WATCHLIST_ROWS: {
    nonLayoutState: true,
    type: PROFILE_TYPES.WATCHLIST_ROWS,
    listKey: 'watchlistRowProfiles',
    idKey: 'watchlistRowProfileId',
    dataKey: 'watchlistRowProfileData',
    defaultProfileId: 0
  },
  KEY_STATS: {
    listKey: 'keyStatsProfiles',
    idKey: 'keyStatsProfileId',
    type: PROFILE_TYPES.KEY_STATS,
    predefinedProfiles: predefinedLayoutKeyStatsProfiles,
    defaultProfileId: predefinedLayoutKeyStatsProfiles[0].id
  }
};


export const generateId = () => {
  return shortUuid.generate();
};

/** @readonly **/
export const CHART_DATE_INITIALIZATION_TYPES = {
  /** Use the date that is currently selected for globally Linked values */
  LINKED_DATE: 'linked_date',
  /** Use today (most recent trading day) */
  TODAY: 'today',
  /** Use the DatePicker date that the user defined. */
  SELECT_DATE: 'select_date'
};


export const createDefaultLayoutTemplate = (layoutArgs = {}) => {
  const scannerId = generateId();
  const newsId = generateId();
  const chartId = generateId();
  const keystatsId = generateId();
  const historicalId = generateId();

  const scanner = createComponentSchema(COMPONENT_TYPES.SCANNER);
  const chart = createComponentSchema(COMPONENT_TYPES.CHART);
  const keystats = createComponentSchema(COMPONENT_TYPES.KEYSTATS);
  const historical = createComponentSchema(COMPONENT_TYPES.HISTORICAL);
  const news = createComponentSchema(COMPONENT_TYPES.NEWS);

  const layout = {
    name: 'New Layout',
    components: {
      [scannerId]: scanner,
      [chartId]: chart,
      [keystatsId]: keystats,
      [historicalId]: historical,
      [newsId]: news
    },
    currentNode: {
      first: scannerId,
      second: {
        first: {
          first: chartId,
          second: newsId,
          direction: 'row',
          splitPercentage: 50
        },
        second: {
          first: keystatsId,
          second: historicalId,
          direction: 'row',
          splitPercentage: 50
        },
        splitPercentage: 50,
        direction: 'column'
      },
      direction: 'row',
      splitPercentage: 40,
    },
    ...layoutArgs
  };

  return {
    layout,
    layoutId: generateId()
  };
};


const createNewsLayoutTemplate = (layoutArgs = {}) => {
  const newsOnlyId = generateId();
  const secOnlyId = generateId();

  const newsOnly = createComponentSchema(COMPONENT_TYPES.NEWS, {
    categories: newsOnlyNewsCategories,
  });
  const secOnly = createComponentSchema(COMPONENT_TYPES.NEWS, {
    categories: secOnlyNewsCategories
  });

  const layout = {
    name: 'New Layout',
    components: {
      [newsOnlyId]: newsOnly,
      [secOnlyId]: secOnly,
    },
    currentNode: {
      first: newsOnlyId,
      second: secOnlyId,
      direction: 'row',
      splitPercentage: 50,
    },
    ...layoutArgs
  };

  return {
    layout,
    layoutId: generateId()
  };
};


export const createOverviewLayoutTemplate = (layoutArgs = {}) => {
  const chart = createComponentSchema(COMPONENT_TYPES.CHART);
  const news = createComponentSchema(COMPONENT_TYPES.NEWS, {
    link: 'white',
  });

  const keystats = createComponentSchema(COMPONENT_TYPES.KEYSTATS);
  const historical = createComponentSchema(COMPONENT_TYPES.HISTORICAL);

  const chartId = generateId()
  const newsId = generateId()
  const keystatsId = generateId()
  const historicalId = generateId()

  const layout = {
    name: 'Overview',
    components: {
      [chartId]: chart,
      [keystatsId]: keystats,
      [historicalId]: historical,
      [newsId]: news
    },
    currentNode: {
      first: {
        first: chartId,
        second: newsId,
        direction: 'column',
        splitPercentage: 50
      },
      second: {
        first: keystatsId,
        second: historicalId,
        direction: 'column',
        splitPercentage: 50
      },
      direction: 'row',
      splitPercentage: 50,
    },
    ...layoutArgs
  };

  return {
    layout,
    layoutId: generateId()
  };
};



/* eslint-disable-next-line no-unused-vars */
const createScannersLayoutTemplate = (layoutArgs = {}) => {
  const largeScannerId = generateId();
  const smallScannerId = generateId();
  const newsId = generateId();
  const keystatsId = generateId();

  const backupProfileId = PROFILE_CONFIG.SCANNER_FILTERS.defaultProfileId

  // Check to see they exist, hopefully prevent some inevitable bugs in the future when we change these.
  const smallScannerProfile = PROFILE_CONFIG.SCANNER_FILTERS.predefinedProfiles.find(p => p.id === `${PREDEF_PREFIX}small-cap`)
  const largeScannerProfile = PROFILE_CONFIG.SCANNER_FILTERS.predefinedProfiles.find(p => p.id === `${PREDEF_PREFIX}large-cap`)

  // Log it missing, and use backupId instead
  if (!smallScannerProfile || !largeScannerProfile) {
    Sentry.captureException(new Error('Missing predefined scanner profiles, for the createScannersLayoutTemplate function. A developer must update the default profile settings.'));
  }

  const smallScanner = createComponentSchema(COMPONENT_TYPES.SCANNER, {
    [PROFILE_CONFIG.SCANNER_FILTERS.idKey]: smallScannerProfile?.id || backupProfileId,
  });
  const largeScanner = createComponentSchema(COMPONENT_TYPES.SCANNER, {
    [PROFILE_CONFIG.SCANNER_FILTERS.idKey]: largeScannerProfile?.id || backupProfileId,
  });

  const news = createComponentSchema(COMPONENT_TYPES.NEWS);
  const keystats = createComponentSchema(COMPONENT_TYPES.KEYSTATS);

  const layout = {
    name: 'New Layout',
    components: {
      [largeScannerId]: largeScanner,
      [smallScannerId]: smallScanner,
      [newsId]: news,
      [keystatsId]: keystats,
    },
    currentNode: {
      first: {
        first: largeScannerId,
        second: {
          first: smallScannerId,
          second: newsId,
          direction: 'column',
          splitPercentage: 50
        },
        direction: 'column',
        splitPercentage: 33.3
      },
      second: keystatsId,
      direction: 'row',
      splitPercentage: 70,
    },
    ...layoutArgs
  };

  return {
    layout,
    layoutId: generateId()
  };
};





/* To avoid potential collisions, we're generating new IDs for each component of a copied layout */
const updateTreeAndComponentIds = (tree, components, idMap) => {
  if (tree === null) return;
  const toCheck = ['first', 'second'];

  toCheck.forEach(prop => {
    if (Object.prototype.hasOwnProperty.call(tree, prop)) {
      if (typeof (tree[prop]) === 'string' && tree[prop] in idMap) {
        const newId = idMap[tree[prop]];
        const oldId = tree[prop];
        // property is ID string, and we want to change it.
        tree[prop] = newId;
        // also update layout.components list
        components[newId] = components[oldId];
        delete components[oldId];
      } else if (typeof (tree[prop]) === 'object') {
        // property is object, and we want to recurse
        updateTreeAndComponentIds(tree[prop], components, idMap);
      }
    }
  });
};


export const copyExistingLayoutSchema = (oldLayout, newName) => {
  const layout = _cloneDeep(oldLayout);
  layout.name = newName;

  const oldToNewIdMap = Object.keys(layout.components).reduce((obj, key) => ({ ...obj, [key]: generateId() }), {});

  updateTreeAndComponentIds(layout.currentNode, layout.components, oldToNewIdMap);

  return {
    layout,
    layoutId: generateId()
  };
};


// Defines all the component-level defaults when a user creates a new component.
export const createComponentSchema = (type, overrides = {}) => {
  let component = {};

  switch (type) {
    case COMPONENT_TYPES.SELECT: {
      component = {};
      break;
    }
    case COMPONENT_TYPES.SCANNER: {
      component = {
        order: 'desc',
        orderby: 'session_chg_p',
        audioEnabled: false,
        trackerOpen: false,
        scannerHeight: 50,
        trackerHeight: 50,
        trackerOptions: { [TRACKER_ACTIONS.added]: true, [TRACKER_ACTIONS.removed]: false },
        [PROFILE_CONFIG.SCANNER_FILTERS.idKey]: PROFILE_CONFIG.SCANNER_FILTERS.defaultProfileId,
        [PROFILE_CONFIG.SCANNER_COLUMNS.idKey]: PROFILE_CONFIG.SCANNER_COLUMNS.defaultProfileId,
      };
      break;
    }
    case COMPONENT_TYPES.NEWS: {
      component = {
        categories: defaultNewsCategories,
        link: LINK_COLORS.white.name,
        searchKeywords: [],
        linkedStateClearedAt: new Date().getTime(),
        [PROFILE_CONFIG.SCANNER_FILTERS.idKey]: PROFILE_CONFIG.SCANNER_FILTERS.defaultProfileId, // SHARES WITH TOP LISTS
        [PROFILE_CONFIG.NEWS_COLUMNS.idKey]: PROFILE_CONFIG.NEWS_COLUMNS.defaultProfileId
      };
      break;
    }
    case COMPONENT_TYPES.KEYSTATS: {
      component = {
        financialsPeriod: 'quarterly',
        [PROFILE_CONFIG.KEY_STATS.idKey]: PROFILE_CONFIG.KEY_STATS.defaultProfileId,
      };
      break;
    }
    case COMPONENT_TYPES.HISTORICAL: {
      component = {
        currentTab: 0,
        recordsOrder: 'desc',
        recordsOrderby: 'day0_date',
        [PROFILE_CONFIG.HISTORY_FILTERS.idKey]: PROFILE_CONFIG.HISTORY_FILTERS.defaultProfileId,
        [PROFILE_CONFIG.HISTORY_COLUMNS.idKey]: PROFILE_CONFIG.HISTORY_COLUMNS.defaultProfileId,
        [PROFILE_CONFIG.HISTORY_AGGREGATES.idKey]: PROFILE_CONFIG.HISTORY_AGGREGATES.defaultProfileId
      };
      break;
    }
    case COMPONENT_TYPES.CHART: {
      component = {
        selectedTemplateId: DEFAULT_TEMPLATE_ID,
        interval: '1D',
        dateInitializationType: CHART_DATE_INITIALIZATION_TYPES.LINKED_DATE
      };
      break;
    }
    case COMPONENT_TYPES.WATCHLIST: {
      component = {
        [PROFILE_CONFIG.WATCHLIST_ROWS.idKey]: PROFILE_CONFIG.WATCHLIST_ROWS.defaultProfileId,
        [PROFILE_CONFIG.SCANNER_COLUMNS.idKey]: PROFILE_CONFIG.SCANNER_COLUMNS.defaultProfileId,
        order: 'desc',
        orderby: WATCHLIST_MANUAL_ORDER
      };
      break;
    }
    case COMPONENT_TYPES.DILUTION: {
      component = {
        title: 'DILUTION'
      };
      break;
    }
    default: {
      throw Error('Invalid component type');
    }
  }

  return {
    type,
    link: LINK_COLORS.white.name,
    ...component,
    ...overrides
  };
};


export const LAYOUT_TEMPLATES = [
  {
    id: 'Default',
    name: 'Default',
    createSchema: createDefaultLayoutTemplate,
  },
  {
    id: 'news',
    name: 'News',
    createSchema: createNewsLayoutTemplate,
  },
  {
    id: 'overview',
    name: 'Overview',
    createSchema: createOverviewLayoutTemplate
  }
];
