import React, { useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { logout } from 'src/redux/account/accountActions';
import { DOCS_WEBSITE_URL } from 'src/constants';
import { useSnackbar } from 'notistack';
import { useIntercom } from 'react-use-intercom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import LogoE from 'src/components/LogoE';
import NavItem from './NavItem';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  useTheme,
  useMediaQuery,
  makeStyles
} from '@material-ui/core';
import {
  TopListsIcon,
  FinancialsIcon,
  HistoryIcon,
  InsidersIcon,
  ChatQuestionMarkIcon,
  SettingsIcon,
  LogOutIcon,
  FilingsIcon
} from 'src/theme/EdgeIcons';


// TODO: Rework nav to not be recursive, so I can keep each navConfig inside one object.

const navConfig = (
  tickerSymbol,
  handleLogout,
  handleIntercom,
) => {
  return [
    [
      {
        title: 'Top Lists',
        icon: TopListsIcon,
        href: `/top-lists`,
      },
      // {
      //   title: 'Overview',
      //   icon: OverviewIcon,
      //   href: `/overview/${tickerSymbol}`
      // },
    ],
    [
      {
        title: 'Financials',
        icon: FinancialsIcon,
        href: `/financials/${tickerSymbol}`
      },
      {
        title: 'Filings',
        icon: FilingsIcon,
        href: `/SEC/${tickerSymbol}`
      },
    ],
    [
      {
        title: 'Insiders',
        icon: InsidersIcon,
        href: `/insiders/${tickerSymbol}`
      },
      {
        title: 'History',
        icon: HistoryIcon,
        href: `/history`
      }
    ],
    [], // for divider
    [
      {
        title: 'Help',
        // href: DOCS_WEBSITE_URL,
        action: handleIntercom,
        icon: ChatQuestionMarkIcon,
        showIfPastDue: true,
        newTab: true
      },
      {
        title: 'Settings',
        href: '/account/general',
        icon: SettingsIcon,
        showIfPastDue: true,
      },
      {
        title: 'Logout',
        action: handleLogout,
        icon: LogOutIcon,
        showIfPastDue: true,
        isLogout: true,
      }
    ]
  ];
};


function renderNavItems({ items }) {
  return (
    <List disablePadding>
      {items.map(item => {
        return (
          <NavItem
            depth={0}
            href={item.href}
            action={item.action}
            icon={item.icon}
            propKey={item.title}
            key={item.title}
            info={item.info}
            title={item.title}
            isLogout={item.isLogout}
            newTab={item.newTab}
          />
        );
      })}
    </List>
  );
}


const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: theme.drawerWidth
  },
  desktopDrawer: {
    width: theme.drawerWidth,
    height: '100%',
    borderRight: 'none'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  edgeESection: {
    borderBottom: `2px solid ${theme.palette.background.tab}`,
    paddingTop: 5,
    paddingBottom: 2,
  },
  sidebarFlex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  topNavCont: {
    flex: 1
  },
  divider: {
    borderBottom: `2px solid ${theme.palette.background.tab}`
  },
  lastDivider: {
    marginTop: -2 // offset to allow last divider to occlude the previous one
  },
  navItemCont: {
    padding: 0,
    '& .MuiButtonBase-root': {
      '& .MuiButton-label': {
        display: 'block',
        textAlign: 'center'
      },
      '& svg': {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        fontSize: '33px'
      }
    }
  },
  lastNavItemCont: {
    '& .MuiList-root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '& .MuiButtonBase-root': {
        paddingBottom: 15
      }
    }
  },
  spacerNavItemCont: {
    flex: 1
  }
}));


const selectLastViewedTickerOrDefault = state => {
  if (state.account?.user?.selectedTicker) {
    return state.account.user.selectedTicker;
  }
  if (state.ticker?.symbol) { // TODO: This is for backwards compatibillity
    return state.ticker.symbol;
  }
  return 'AAPL';
}


function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const tickerId = useSelector(selectLastViewedTickerOrDefault);
  const {
    isOpen: isIntercomOpen,
    shutdown: shutdownIntercom,
    hide: hideIntercom,
    show: showIntercom,
    showSpace: showSpaceIntercom
  } = useIntercom();


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);


  const renderScrollComponent = useCallback((scrollChildren) => {
    if (isMobile) {
      return <div className={classes.sidebarFlex}>{scrollChildren}</div>;
    } else {
      return <PerfectScrollbar options={{ suppressScrollX: true }} className={classes.sidebarFlex}>{scrollChildren}</PerfectScrollbar>;
    }
  }, [isMobile, classes.sidebarFlex]);


  const handleLogout = useCallback(() => {
    try {
      dispatch(logout(shutdownIntercom));
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  }, [logout, shutdownIntercom, enqueueSnackbar]);


  const handleIntercom = useCallback(() => {
    // showSpaceIntercom('home');
    if (isIntercomOpen) {
      hideIntercom()
    } else {
      showSpaceIntercom('home')
    }
  }, [isIntercomOpen, hideIntercom, showIntercom])


  const navlists = useMemo(() => {
    return navConfig(tickerId, handleLogout, handleIntercom);
  }, [tickerId, handleLogout, handleIntercom])


  const content = (
    <div className={classes.sidebarFlex}>
      <Box
        p={1}
        display="flex"
        justifyContent="center"
        className={classes.edgeESection}
      >
        <RouterLink to={`/overview/${tickerId}`}>
          <LogoE size={21} />
        </RouterLink>
      </Box>

      {navlists.map((items, i) => {
        const last = i === navlists.length - 1;
        const secondToLast = i === navlists.length - 2;
        const spacer = items.length === 0;
        return (
          <React.Fragment key={i}>
            <Box className={clsx(
              classes.navItemCont,
              last && classes.lastNavItemCont,
              spacer && classes.spacerNavItemCont
            )}>
              {!spacer && (
                <List key={i} style={{ paddingBottom: 0, paddingTop: 0 }}>
                  {renderNavItems({ items })}
                </List>
              )}
            </Box>
            {!last && <Divider className={clsx(classes.divider, secondToLast && classes.lastDivider)} />}
          </React.Fragment>
        );
      })}
    </div>
  );

  return (
    <>
      <Hidden smUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          // open
          variant="temporary"
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
          >
            {renderScrollComponent(content)}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
          >
            {renderScrollComponent(content)}
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
