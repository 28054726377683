import { HARD_CODED_COMPONENTS, PROFILE_CONFIG } from "./historyPageIntermediateSchema";
import shortUuid from 'short-uuid';

export const STORE_KEY = 'historyPageIntermediate';

export const INITIALIZE_START = `@${STORE_KEY}/INIT/START`
export const INITIALIZE_SUCCESS = `@${STORE_KEY}/INIT/SUCCESS`

export const UPDATE_COMPONENT = `@${STORE_KEY}/update-component`;
export const ADD_COMPONENT = `@${STORE_KEY}/add-component`;
export const UPDATE_PROFILE = `@${STORE_KEY}/update-profile`;
export const MODIFY_PROF = `@${STORE_KEY}/modify-prof`;
export const COPY_PROF = `@${STORE_KEY}/copy-prof`;
export const DELETE_PROF = `@${STORE_KEY}/delete-prof`;
export const DELETE_COMPONENT = `@${STORE_KEY}/delete-component`;

export const UPDATE_UI_COMPONENT_STATE = `@${STORE_KEY}/update-ui-component-state`;


/** 
 * @typedef {Object} Action
 * @property {string} type
 * @property {object} payload
 */


/** @typedef {function(dispatch, getState): void} Thunk */



/**
 * Update UI state for a component, which is NOT persisted to db/local
 * @param {keyof HARD_CODED_COMPONENTS} componentId
 * @param {object} data
 * @returns {Action}
 */
export function updateUiComponentState(componentId, data = {}) {
  return {
    type: UPDATE_UI_COMPONENT_STATE,
    payload: { componentId, data }
  };
}



/**
 * @param {object[]} profileSettings
 * @param {keyof HARD_CODED_COMPONENTS} componentId
 * @param {object[]} expressions
 * @param {object} profileConfig
 * @returns {Thunk}
 **/
export const updateProfileSettings = (
  profileSettings,
  componentId,
  profileConfig,
  expressionPayload = {},
) => (dispatch, getState) => {

  const { expressions: expressionState } = getState();
  // TODO: HIST_EXP
  // find deleted expression
  // search entire state...
  // dispatch all actions...
  // what about syncing with broadcast?

  dispatch({
    type: UPDATE_PROFILE,
    payload: {
      profileSettings,
      componentId,
      expressionPayload,
      profileConfig
    }
  });
}


/**
 * Update component data
 * @param {keyof HARD_CODED_COMPONENTS} componentId
 * @param {object} data
 * @returns {Action}
 */
export function updateComponent(componentId, data = {}) {
  return {
    type: UPDATE_COMPONENT,
    payload: { componentId, data }
  };
}


