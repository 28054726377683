import { grammar as createGrammar } from 'ohm-js';
import { source, makeInterpreter, ArithmaticPermissionsError } from './grammar';
import { insertValuesIntoExpression } from '../expressionUtils/expressionMappings';


class InterpreterState {
  constructor() {
    this.current = {};
  }

  reset = () => {
    this.current = {};
  }

  count(key) {
    if (!this.current[key]) {
      this.current[key] = 0;
    }
    return this.current[key]++;
  }

  get(key, _default = null) {
    return this.current[key] || _default;
  }

  set(key, value) {
    this.current[key] = value;
  }
}

/**
 * Validate Expression strings
 **/
class ArithmeticParser {
  constructor({
    maxDepth = 16,
    maxArguments = 8,
    maxPlaceholders = 6,
  }) {
    this.maxDepth = maxDepth;
    this.maxArguments = maxArguments;
    this.maxPlaceholders = maxPlaceholders;

    this.grammar = createGrammar(source);
    this.semantics = this.grammar.createSemantics();
    this.state = new InterpreterState();

    const interpreter = makeInterpreter(this.state, {
      maxDepth,
      maxArguments,
      maxPlaceholders,
    });

    this.semantics.addOperation('interpret', interpreter);
  }

  /**
   * Validate the expression syntactically and logically.
   *
   * @throws {Error} - If the expression is invalid. Contains a user message.
   * @param {string} expression - "10 + ([[A]] * 2) / GREATEST(5, [[b]]])"
   * @returns {boolean} - If the expression is valid
   **/
  validate = (expression) => {
    this.state.reset();

    if (!(typeof expression === 'string')) {
      throw new Error('Expression must be a string');
    }
    if (!expression) {
      throw new Error('Expression cannot be empty');
    }

    const match = this.grammar.match(expression);

    if (match.failed()) {
      throw new Error(match.shortMessage);
    }

    try {
      const cst = this.semantics(match);
      const result = cst.interpret();
    } catch (err) {
      if (err instanceof ArithmaticPermissionsError) {
        throw new Error(err.message);
      } else {
        console.error(err);
        throw new Error('Unexpected error');
      }
    }

    return true;
  }

  /**
   * When we have Column data from the DB, replace the placeholders with the given values
   * and evaluate to an actual number.
   * - This path allows NULL tokens, GREATEST(1, Null, 3) is a valid expression.
   * - This path does not allow Placeholders during evaluation. They must be replaced first.
   * 
   * @throws {Error} - Expression is not able to be evaluated
   * @param {ExpressionProfile} profile
   * @param {Object<str, any>} values - Indexed by column name
   * @returns {number} - The evaluation result
   **/
  evaluate = (expressionObj, values) => {
    this.state.reset();
    // This is so hacky it hurts. We should make a seperate interpreter.
    this.state.set('allowNullToken', true);
    this.state.set('allowColumnPlaceholders', false);

    if (!expressionObj) {
      throw new Error('Expression object is missing');
    }
    if (!values) {
      throw new Error('Values object is missing');
    }

    const substitutedExpression = insertValuesIntoExpression(expressionObj, values);

    const match = this.grammar.match(substitutedExpression);

    if (match.failed()) {
      console.warn('Substituted expr:', substitutedExpression);
      throw new Error(match.shortMessage);
    }

    const cst = this.semantics(match);
    return cst.interpret();
  }
}



let instance = null;


/**
 * Singleton factory for the ArithmeticParser
 * @returns {ArithmeticParser}
 **/
export default function getArithmaticParser() {
  if (!instance) {
    instance = new ArithmeticParser({
      maxDepth: 10,
      maxArguments: 8,
      maxPlaceholders: 6,
    });
  }
  return instance;
}


