import React, { useMemo, useState } from 'react';
import {
  FILTER_COLUMNS,
  GROUPS,
} from 'src/app/components/grid/topListHistorical/columns/columnDefs';
import { PROFILE_CONFIG } from 'src/redux/layout/topListLayoutSchema';
import profileReducer, {
  ADD_PROFILE,
  COPY_PROFILE,
  generateId
} from 'src/app/slicedForm/shared/reducers/profileReducer';
import { formToProfile, profileToForm, FILTER_FORM_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import FormProvider from 'src/app/slicedForm/shared/context/FormProvider';
import Form from 'src/app/slicedForm/FilterForm/elements/Form';
import ColumnDefsProvider from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import filterReducer from 'src/app/slicedForm/FilterForm/reducers/filterReducer';
import expressionReducer from 'src/app/slicedForm/shared/reducers/expressionReducer';
import FormModal from 'src/app/components/FormModal';
import PropTypes from 'prop-types';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import { MergeType } from '@material-ui/icons';
import {
  SCANNER_FUSE_OPTIONS
} from 'src/app/slicedForm/shared/schema/synonyms';
import { useProfilePermissions } from 'src/hooks/useUserPlanPermissions';
import FormOverlayModalProvider, { ModalConfig } from 'src/app/slicedForm/shared/context/FormOverlayModalProvider';
import ModalContentCopyPredefined from 'src/app/slicedForm/shared/elements/ModalContentCopyPredefined';
import MarketingModalTickerStatsAggregatesUpgrade from 'src/app/components/MarketingModal/MarketingModalTickerStatsAggregatesUpgrade';


const serialize = (state) => profileToForm(state, FILTER_FORM_TYPES.AGGREGATE);
const deserialize = (state) => formToProfile(state, FILTER_FORM_TYPES.AGGREGATE);



const settings = { timeSlice: false }


// FIND: SLICED_FORM_INSTANCE
function HistoricalSlicedAggregatesForm({
  profiles,
  activeProfile,
  expressions,
  oldestDateAvailable,
  disabled,
  onSubmit,
}) {
  const [open, setOpen] = useState(false);
  const permissions = useProfilePermissions('toplist', PROFILE_CONFIG.HISTORY_AGGREGATES.listKey)

  const initialState = useMemo(() => {
    return {
      profiles,
      activeProfile,
      expressions,
    };
  }, [profiles, activeProfile, expressions]);

  const reducers = useMemo(() => {
    return [profileReducer, filterReducer, expressionReducer];
  }, []);

  const formActions = useMemo(() => ({
    onSubmit: ({ state }) => {
      onSubmit(deserialize(state));
      setOpen(false);
    },
    onSave: ({ state }) => {
      onSubmit(deserialize(state));
    },
    onCancel: () => {
      setOpen(false);
    },
    onCreate: ({ dispatch }) => {
      // Can't use defaults because its not serialized.
      // Just copy the serialized one.
      const idToCopy = PROFILE_CONFIG.HISTORY_AGGREGATES.predefinedProfiles[0].id;
      dispatch({
        type: ADD_PROFILE, payload: {
          id: idToCopy,
          newId: generateId()
        }
      });
    },
    onCopy: ({ state, dispatch }) => {
      const id = state?.activeProfile;
      dispatch({
        type: COPY_PROFILE, payload: {
          id,
          newId: generateId()
        }
      });
    }
  }), []);

  // Individual ColumnDefs can override these
  const defaultDateConfig = useMemo(() => {
    return {
      minDate: oldestDateAvailable,
      disableFuture: true,
      disableWeekend: true,
      disableHoliday: true,
      makeRollingDates: undefined,
      makeInputDates: undefined,
      makeStaticDates: undefined,
      staticRangeHeaderContent: undefined,
      format: 'yyyy-MM-dd',
      placeholder: 'yyyy-mm-dd'
    }
  }, [oldestDateAvailable])


  return (
    <>
      <PanelIconButton
        Icon={MergeType}
        disabled={disabled}
        onClick={() => setOpen(true)}
        text="Ticker Stats"
        shouldHideIconText={true}
      />
      <FormModal
        open={open}
        width={1108}
        height={900}
        onClose={formActions.onCancel}
      >
        <FormProvider
          initialState={initialState}
          reducers={reducers}
          initFunc={serialize}
          actions={formActions}
          permissions={permissions}
          settings={settings}
        >
          <ColumnDefsProvider
            columnDefs={FILTER_COLUMNS}
            columnGroups={GROUPS}
            fuseOptions={SCANNER_FUSE_OPTIONS}
            defaultDateConfig={defaultDateConfig}
          >
            <FormOverlayModalProvider>
              <Form />

              <ModalConfig name="upgrade_customization" width={450} sharpBorder>
                <MarketingModalTickerStatsAggregatesUpgrade />
              </ModalConfig>
            </FormOverlayModalProvider>
          </ColumnDefsProvider>
        </FormProvider>
      </FormModal>
    </>
  );
}


HistoricalSlicedAggregatesForm.propTypes = {
  profiles: PropTypes.array.isRequired,
  activeProfile: PropTypes.string.isRequired,
  expressions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};


HistoricalSlicedAggregatesForm.defaultProps = {
  expressions: []
}


export default React.memo(HistoricalSlicedAggregatesForm);
