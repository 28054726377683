import { combineReducers } from 'redux';
import accountReducer from 'src/redux/account/accountReducer';
import tickerReducer from 'src/redux/ticker/tickerReducer';
import statTables from 'src/redux/statTables/statTablesReducer';
import keyStats from 'src/redux/keyStats/keyStatsReducer';
import tradingViewChart from 'src/redux/tradingViewChart/tradingViewChartReducer';
import newsReducer from 'src/redux/news/newsReducer';
import historyReducer from 'src/redux/history/historyReducer';
import realtimeReducer from 'src/redux/realtime/realtimeReducer';
import secFilingsReducer from 'src/redux/secFilings/secFilingsReducer';
import exportReducer from 'src/redux/exports/exportReducer';
import profileSettings from 'src/redux/profileSettings/profileSettingsReducer';
import topListLayoutReducer from 'src/redux/layout/topListLayoutReducer';
import notificationReducer from 'src/redux/notifications/notificationReducer';
import financialsReducer from 'src/redux/financials/financialsReducer';
import globalExpressionReducer from '../expressions/globalExpressionReducer';

import historyPageIntermediateReducer from '../historyPageIntermediate/historyPageIntermediateReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  ticker: tickerReducer,
  statTables: statTables,
  keyStats: keyStats,
  tvchart: tradingViewChart,
  news: newsReducer,
  secFilings: secFilingsReducer,
  history: historyReducer,
  realtime: realtimeReducer,
  exports: exportReducer,
  profileSettings: profileSettings,
  toplist: topListLayoutReducer,
  financials: financialsReducer,
  notification: notificationReducer,
  expressions: globalExpressionReducer,
  historyPageIntermediate: historyPageIntermediateReducer
});

export default rootReducer;
