/**
 * Copies and pastes EXACTLY as is. Attempts to identify columns from Labels,
 * matching longest first. 
 *
 * Impossible to validate, and partial matches are possible.
 **/
export function createCopyPastePlainStrategy({ colDefs = [], tagName = 'mention' } = {}) {
  return {
    paste: (view, event) => {
      const plainText = event.clipboardData.getData('text/plain');

      if (plainText) {
        // Sort the colDefs by label length in descending order to match the longest columns first
        const sortedColDefs = colDefs.map(d => ({ name: d.name, label: d.label })).sort((a, b) => b.label.length - a.label.length);

        // Array to hold the resulting nodes (mentions and text)
        const nodes = [];

        let index = 0;

        // Iterate through the plain text character by character
        while (index < plainText.length) {
          if (plainText[index] === '@') {
            // Look for a match with the longest columnDef first
            let foundMatch = false;
            for (const colDef of sortedColDefs) {
              const labelLength = colDef.label.length;
              const potentialMatch = plainText.slice(index + 1, index + 1 + labelLength);

              if (potentialMatch === colDef.label) {
                // Create a mention node using the column definition's name and label
                nodes.push(view.state.schema.nodes[tagName].create({
                  id: colDef.name,
                  label: colDef.label,
                }));

                // Move the index forward past the matched label
                index += labelLength + 1; // +1 for the "@" symbol
                foundMatch = true;
                break; // Stop looking for matches once we find the longest one
              }
            }

            if (!foundMatch) {
              // If no column matches, treat the "@" as part of the regular text
              nodes.push(view.state.schema.text('@'));
              index++; // Move past the "@" symbol
            }
          } else {
            // If no "@" is found, continue adding plain text nodes
            let nextAtIndex = plainText.indexOf('@', index);
            if (nextAtIndex === -1) nextAtIndex = plainText.length;

            // Add the plain text between the current position and the next "@"
            nodes.push(view.state.schema.text(plainText.slice(index, nextAtIndex)));
            index = nextAtIndex;
          }
        }

        // Create a transaction to insert the nodes
        const { tr } = view.state;
        const transaction = tr.replaceSelectionWith(view.state.schema.nodes.paragraph.create(null, nodes));
        view.dispatch(transaction);

        event.preventDefault(); // Prevent the default paste behavior
        return true;
      }
      return false;
    }
  }
}

/**
 * Copies the string and serializes into "10 + @day0_vol" format.
 * Allows us to validate the columns, but may be confusing to users
 * when they store it.
 **/
export function createCopyPasteNameSerializationStrategy({ colDefs = [], tagName = 'mention' } = {}) {
  return {
    copy: (view, event) => {
      const { state } = view;
      const { from, to } = state.selection;
      const selectedContent = state.doc.cut(from, to); // Selected portion

      const plainText = selectedContent.content.content[0].content.content.map((node, index, array) => {
        if (node.type.name === tagName) {
          // Convert mention to the string format with just the ID
          return `@${node.attrs.id}`;
        }
        // Return plain text nodes unchanged
        return node.text || '';  // Regular text
      }).join('').trim();

      // Set clipboard data (only using plain text)
      event.clipboardData.setData('text/plain', plainText); // Fallback for plain text editors
      event.preventDefault();
    },

    paste: (view, event) => {
      const planTextOriginal = event.clipboardData.getData('text/plain');

      if (planTextOriginal) {
        let plainText = planTextOriginal;

        if (!plainText.startsWith(' ')) {
          plainText = ' ' + plainText;
        }
        if (!plainText.endsWith(' ')) {
          plainText += ' ';
        }

        const mentionPattern = /@([a-zA-Z0-9_]+)/g;

        const nodes = [];

        let lastIndex = 0;
        let match;

        while ((match = mentionPattern.exec(plainText)) !== null) {
          const [fullMatch, id] = match;

          // Add any preceding text (including whitespace) as a text node
          if (match.index > lastIndex) {
            const textBefore = plainText.slice(lastIndex, match.index);
            nodes.push(view.state.schema.text(textBefore));
          }

          // Lookup the column definition for the mention
          const colDef = colDefs.find(col => col.name === id);

          if (colDef) {
            nodes.push(view.state.schema.nodes.mention.create({
              id,
              label: colDef.label, // Use the label from columnDefs
            }));
          } else {
            // If no column is found, insert the plain text. 
            // Remove the @ symbol to prevent dropdown from opening.
            nodes.push(view.state.schema.text(fullMatch.replaceAll('@', '')));
          }

          lastIndex = mentionPattern.lastIndex;
        }

        // Add any remaining text after the last mention
        if (lastIndex < plainText.length) {
          nodes.push(view.state.schema.text(plainText.slice(lastIndex)));
        }

        // Create a transaction to insert the nodes
        const { tr } = view.state;
        const transaction = tr.replaceSelectionWith(view.state.schema.nodes.paragraph.create(null, nodes));
        view.dispatch(transaction);

        event.preventDefault(); // Prevent the default paste behavior
        return true;
      }
      return false;
    }
  }
}
