import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { UPDATE_EXPRESSIONS, EXPR_CTX, makeSelectExpression, makeSelectListExpressions, cleanExpression } from './globalExpressionReducer';
import useParameterizedSelector from 'src/hooks/useParameterizedSelector';


const defaultValue = {
  contextKey: null,
}

const GlobalExpressionContext = createContext(defaultValue);





/**
  * A connector intended to wrap around SlicedForms.
  * Specifies which context to use for expressions (realtime, historical),
  * and how to select/dispatch them.
  *
  * Passing a null contextKey will "disable" expressions for the childen.
  **/
function GlobalExpressionProvider({
  contextKey,
  children
}) {
  const value = useMemo(() => {
    return {
      contextKey,
      isExpressionsAvailable: Boolean(contextKey)
    }
  }, [contextKey]);

  return (
    <GlobalExpressionContext.Provider value={value}>
      {children}
    </GlobalExpressionContext.Provider>
  );
}


/** 
 * @typedef {Object} ExpressionPayload
 * @property {object[]} expressions - List of expressions to (potentially) update
 * @property {string} contextKey - Context to update
 * @property {number} timestamp - Timestamp of the update
 */

/**
 * Check if expressions are available for the current context.
 * NOTE: In slicedForms, it may make sense to use FormSettings rather than this.
 * Fine for now I guess.
 **/
export const useIsExpressionsAvailable = () => {
  const { contextKey } = useContext(GlobalExpressionContext);
  return Boolean(contextKey);
}


/**
 * Select all expressions from Redux matching your current Context key.
 **/
export const useListGlobalExpressions = () => {
  const { contextKey } = useContext(GlobalExpressionContext);
  return useParameterizedSelector(makeSelectListExpressions, contextKey);
}


/**
 * Add the context into an outgoing expression payload.
 * @returns {function(object[]): ExpressionPayload}
 */
export const useMakeExpressionPayload = () => {
  const { contextKey } = useContext(GlobalExpressionContext);

  return useCallback((expressions) => {
    const cleaned = expressions.map(exp => cleanExpression(exp, contextKey))
    return { expressions: cleaned, contextKey, timestamp: + new Date() };
  }, [contextKey])
}


/**
 * Combine list+payload
 * @returns {[object[], function(object[]): ExpressionPayload]} - [expressions, makeExpressionAction]
 **/
export const useGlobalExpressions = () => {
  const expressions = useListGlobalExpressions();
  const makeExpressionPayload = useMakeExpressionPayload();
  return [expressions, makeExpressionPayload];
}





export default GlobalExpressionProvider;
