import objectScan from 'object-scan';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { ACTIONS } from './constants';

/** @typedef {import('./constants.js').RevisionRule} RevisionRule */
/** @typedef {import('./constants.js').ReadRule} ReadRule */
/** @typedef {import('./constants.js').ChangeItem} ChangeItem */


/*
 TODO: This might be too slow. Ideas:
  1) Manually do this yourself with isEqual
  2) Use deep-diff, and convert arrays to objects somehow
  3) Try to speed up object-scan
*/



const hasMapping = (rule) => {
  return 'mapRecord' in rule || 'filterRecord' in rule;
}


/**
 * Apply our readModifications before initializing the reducer.
 * This is useful for applying mappings to the entire collection.
 * Will most likely be called no matter which source the data is from (db/local/init)
 *
 * @param {object} state - Make sure this is NOT a direct reference to store state!
 * @param {Object.<string, ReadRule>} rules
 * @returns {object}
 */
export function applyInitializationReadModifications(state, rules) {
  const d = + new Date();

  // Because we're modifying state directly, we can skip over rules that don't apply changes
  const patterns = Object.keys(rules).filter(k => hasMapping(rules[k]));

  objectScan(patterns, {
    filterFn: ({ isMatch, getKey, getValue, getParent, getMatchedBy }) => {
      if (!isMatch) {
        return;
      }

      const matchedBy = getMatchedBy();
      const value = getValue();
      const pathParts = getKey();
      const parent = getParent();
      const rule = rules[matchedBy[0]];
      const { mapRecord, filterRecord } = rule;

      const filterResult = filterRecord?.({
        pathParts, record: value, records: parent, state
      });

      if (filterResult === false) {
        if (Array.isArray(parent)) {
          const index = pathParts[pathParts.length - 1];
          parent.splice(index, 1); // Mutate the parent array directly
        } else if (_isObject(parent)) {
          const key = pathParts[pathParts.length - 1];
          delete parent[key];
        }
        return; // Stop further processing if item is removed
      }

      if (mapRecord) {
        _set(state, pathParts, mapRecord({
          pathParts, record: value, records: parent, state
        }));
      }
    },
    useArraySelector: true,
    joined: false,
  })(state);

  console.log('applyInitializationReadModifications TIME:', + new Date() - d, 'ms', patterns);

  return state;
}


/**
 * Apply our writeModifications before saving to localstorage.
 * TODO: We could potentially build this in-unison with buildChangeset.
 *
 * @param {object} state
 * @param {Object.<string, RevisionRule>} rules
 * @param {string[]} ignorePaths
 * @returns {object}
 */
export function applyLocalstorageWriteModifications(
  state,
  rules,
  ignorePaths,
) {
  const d = + new Date();

  const filteredState = _cloneDeep(Object.keys(state).reduce((acc, curr) => {
    if (ignorePaths.includes(curr)) {
      return acc;
    }
    acc[curr] = state[curr];
    return acc;
  }, {}));

  // Because we're modifying state directly, we can skip over rules that don't apply changes
  const patterns = Object.keys(rules).filter(k => hasMapping(rules[k]));

  objectScan(patterns, {
    filterFn: ({ isMatch, getKey, getValue, getParent, getMatchedBy }) => {
      if (!isMatch) {
        return;
      }
      const matchedBy = getMatchedBy();
      const value = getValue();
      const pathParts = getKey();
      const parent = getParent();
      const rule = rules[matchedBy[0]];
      const { mapRecord, filterRecord } = rule;

      const filterResult = filterRecord?.({
        pathParts, record: value, records: parent, state: filteredState
      });

      if (filterResult === false) {
        if (Array.isArray(parent)) {
          const index = pathParts[pathParts.length - 1];
          parent.splice(index, 1); // Mutate the parent array directly
        } else if (_isObject(parent)) {
          const key = pathParts[pathParts.length - 1];
          delete parent[key];
        }
        return; // Stop further processing if item is removed
      }

      if (mapRecord) {
        _set(filteredState, pathParts, mapRecord({
          pathParts, record: value, records: parent, state: filteredState
        }));
      }
    },
    useArraySelector: true,
    joined: false,
  })(filteredState);

  console.log('applyLocalstorageWriteModifications TIME:', + new Date() - d, 'ms');

  return filteredState;
}



/**
 * Finds DELETE items. You must call this function with prevStatem as the search target.
 * @param {object} prevState
 * @param {object} nextState
 * @param {Object.<string, RevisionRule>} rules
 * @param {ChangeItem[]} [changes]
 * @returns {function}
 */
const makeDeleteCallback = (
  prevState,
  nextState,
  rules,
  changes = []
) => {
  return ({ isMatch, getKey, getValue, getParent, getMatchedBy }) => {
    if (!isMatch) {
      return;
    }
    const matchedBy = getMatchedBy();
    const prevValue = getValue();
    const pathParts = getKey();
    const rule = rules[matchedBy[0]];

    if (matchedBy.length > 1) {
      console.error('Multiple patterns matched', { matchedBy, pathParts });
      return;
    }

    if (!('delete' in rule)) {
      return;
    }

    const prevParent = getParent();
    const { filterRecord, mapRecord } = rule;

    const filterResult = filterRecord?.({
      pathParts, record: prevValue, records: prevParent, state: prevState
    });

    if (filterResult === false) {
      return;
    }

    const itemId = rule.getId({ pathParts, record: prevValue, parent: prevParent, state: prevState });
    const itemRecordType = rule.getRecordType({ pathParts, record: prevValue, parent: prevParent, state: prevState });

    let nextValue;

    // Special handling for arrays, e.g., 'profiles.*.[*]' 
    // UNUSED!
    if (Array.isArray(prevParent)) {
      const nextParent = _get(nextState, pathParts.slice(0, -1)) || [];
      nextValue = nextParent.find(it => rule.getId({ pathParts, record: it, parent: prevParent, state: prevState }) === itemId);
    } else {
      nextValue = _get(nextState, pathParts);
    }

    if (prevValue && nextValue === undefined) {
      changes.push({
        action: ACTIONS.delete,
        revision: Boolean(rule.delete),
        type: itemRecordType,
        id: itemId,
      })
    }
  }
}


/**
 * Finds PUT or ADD items. Call this with nextState as the target.
 * @param {object} prevState
 * @param {object} nextState
 * @param {RevisionRule[]} rules
 * @param {ChangeItem[]} [changes]
 * @returns {function}
 **/
const makePutOrAddCallback = (
  prevState,
  nextState,
  rules,
  changes = []
) => {
  return ({ isMatch, getKey, getValue, getParent, getMatchedBy }) => {
    if (!isMatch) {
      return;
    }
    const matchedBy = getMatchedBy();
    const nextValue = getValue();
    const pathParts = getKey();
    const rule = rules[matchedBy[0]];

    if (matchedBy.length > 1) {
      console.error('Multiple patterns matched', { matchedBy, pathParts });
    }

    if (!('put' in rule) && !('add' in rule)) {
      return;
    }

    const nextParent = getParent();
    const { filterRecord, mapRecord } = rule;

    const filterResult = filterRecord?.({
      pathParts, record: nextValue, records: nextParent, state: nextState
    });

    if (filterResult === false) {
      return
    }

    const itemId = rule.getId({ pathParts, record: nextValue, parent: nextParent, state: nextState });
    const itemRecordType = rule.getRecordType({ pathParts, record: nextValue, parent: nextParent, state: nextState });

    let prevValue;

    // Special handling for arrays, e.g., 'profiles.*.[*]'
    // UNUSED!
    if (Array.isArray(nextParent)) {
      const prevParent = _get(prevState, pathParts.slice(0, -1)) || [];
      prevValue = prevParent.find(it => rule.getId({ pathParts, record: it, parent: nextParent, state: nextState }) === itemId);
    } else {
      prevValue = _get(prevState, pathParts);
    }

    const change = {
      type: itemRecordType,
      id: itemId,
      data: mapRecord ? mapRecord({ pathParts, record: nextValue, parent: nextParent, state: nextState }) : nextValue,
    };

    if (nextValue && prevValue === undefined) {
      if (!('add' in rule)) {
        console.error('Cannot ADD', { pathParts, nextValue });
        return;
      }
      change.action = ACTIONS.add;
      change.revision = Boolean(rule.add);
    } else if (prevValue && nextValue && !_isEqual(prevValue, nextValue)) {
      if (!('put' in rule)) {
        console.error('Cannot PUT', { pathParts, prevValue, nextValue });
        return;
      }
      change.action = ACTIONS.put;
      change.revision = Boolean(rule.put);
    } else {
      // No change
      return;
    }

    changes.push(change);
  }
}


/**
 * Buld the Changeset request to be sent to the server.
 * Does not involve localstorage or redux.
 * Will apply RevisionRule write mappings
 * @param {object} prevState
 * @param {object} nextState
 * @param {Object.<string, RevisionRule>} rules
 * @returns {ChangeItem[]}
 **/
export function buildChangeset(prevState, nextState, rules) {
  const deletePatterns = Object.keys(rules).filter(k => ACTIONS.delete in rules[k]);
  const putOrUpdatePatterns = Object.keys(rules).filter(k => ACTIONS.put in rules[k] || ACTIONS.add in rules[k]);

  const changes = [];

  const deleteCb = makeDeleteCallback(prevState, nextState, rules, changes);
  const putOrUpdateCb = makePutOrAddCallback(prevState, nextState, rules, changes);

  objectScan(deletePatterns, {
    filterFn: deleteCb,
    useArraySelector: true,
    joined: false,
  })(prevState);

  objectScan(putOrUpdatePatterns, {
    filterFn: putOrUpdateCb,
    useArraySelector: true,
    joined: false,
  })(nextState);

  return changes;
}



/**
 * Initialize the server on initial load.
 * We could instead write a new endpoint that accepts a full state object.
 * This is easier (I think), but bigger network cost and slower to calculate.
 * @param {object} state
 * @param {Object.<string, RevisionRule>} rules
 * @returns {ChangeItem[]}
 */
export function buildInitializationChangeset(initialState, rules) {
  const t = + new Date();

  const changes = [];

  const putOrUpdateCb = makePutOrAddCallback({}, initialState, rules, changes);

  objectScan(Object.keys(rules), {
    filterFn: putOrUpdateCb,
    useArraySelector: true,
    joined: false,
  })(initialState);

  console.log('buildInitializationChangeset TIME:', + new Date() - t, 'ms');

  return changes;
}




