import * as Sentry from '@sentry/react';
import React, { useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Subscription from 'src/services/Subscription';
import { predefinedLayoutHistoryAggregateProfiles } from 'src/redux/layout/topListLayoutProfileBases';

export const MAX_YEARS = 14;
export const MAX_EXPRESSIONS_LIMIT = 30;

const plan_level_heirarchy = ['basic', 'pro', 'pro_plus'];

export const isNextPlanLevelDowngrade = (currentPlanLevel, nextPlanLevel) => {
  let currplan = currentPlanLevel;
  if (currentPlanLevel === 'test') {
    currplan = 'pro_plus'
  }

  return plan_level_heirarchy.indexOf(currplan) > plan_level_heirarchy.indexOf(nextPlanLevel);
}


export const PLAN_LEVELS = {
  basic: 'basic',
  pro: 'pro',
  pro_plus: 'pro_plus',
  test: 'test'
}

export const PLAN_LEVEL_LABELS = {
  [PLAN_LEVELS.basic]: 'Basic',
  [PLAN_LEVELS.pro]: 'Pro',
  [PLAN_LEVELS.pro_plus]: 'Pro +',
  [PLAN_LEVELS.test]: 'Pro + (TEST)'
}


export const PROFILE_PERMISSION_SCOPES = {
  disabledCustomization: 'disabledCustomization',
  disallowedPredefinedProfiles: 'disallowedPredefinedProfiles'
}

// Passed to forms directly, so we can use defaults in situation where permissions don't 
// make sense.
export const profilePermissionDefaults = {
  [PROFILE_PERMISSION_SCOPES.disabledCustomization]: {
    type: 'boolean',
    value: false,
    unlocksAt: null,
  },
  [PROFILE_PERMISSION_SCOPES.disallowedPredefinedProfiles]: {
    type: 'id_array',
    value: [],
    unlocksAt: null,
  }
}



export const profilePermissions = {
  toplist: {
    historyAggregateProfiles: {
      [PROFILE_PERMISSION_SCOPES.disabledCustomization]: {
        basic: true,
        unlocksAt: PLAN_LEVELS.pro
      },
      [PROFILE_PERMISSION_SCOPES.disallowedPredefinedProfiles]: {
        basic: predefinedLayoutHistoryAggregateProfiles.slice(1).map(p => p.id),
        unlocksAt: PLAN_LEVELS.pro
      }
    }
  },
  historyPage: {
    // We probably want to call out the date restriction inside a modal. Maybe in Toplist too.
    historyAggregateProfiles: {
      [PROFILE_PERMISSION_SCOPES.disabledCustomization]: {
        basic: true,
        unlocksAt: PLAN_LEVELS.pro
      },
      [PROFILE_PERMISSION_SCOPES.disallowedPredefinedProfiles]: {
        basic: predefinedLayoutHistoryAggregateProfiles.slice(1).map(p => p.id),
        unlocksAt: PLAN_LEVELS.pro
      }
    },
    historyTimeseriesProfiles: {
      [PROFILE_PERMISSION_SCOPES.disabledCustomization]: {
        basic: true,
        unlocksAt: PLAN_LEVELS.pro
      },
      [PROFILE_PERMISSION_SCOPES.disallowedPredefinedProfiles]: {
        basic: predefinedLayoutHistoryAggregateProfiles.slice(1).map(p => p.id),
        unlocksAt: PLAN_LEVELS.pro
      }
    }
  }
}


export function useProfilePermissions(layoutKey, profileListKey) {
  const planLevel = usePlanLevel();

  const scopes = {};

  if (profilePermissions?.[layoutKey]?.[profileListKey]) {
    Object.entries(profilePermissions[layoutKey][profileListKey]).forEach(([scopeName, levels]) => {
      if (planLevel in levels) {
        scopes[scopeName] = { value: levels[planLevel], unlocksAt: levels.unlocksAt }
      }
    });
  }

  return { planLevel, ...scopes }

  // return useMemo(() => {
  //   const permissions = Object.entries(profilePermissionDefaults)
  //     .reduce((acc, curr) => {
  //       const [scope, { type, defaultValue }] = curr;
  //       const values = profilePermissions?.[layoutKey]?.[profileListKey]?.[scope];
  //       const valueForPlan = values?.[planLevel] === undefined ? defaultValue : values[planLevel];
  //       const unlocksAt = values?.unlocksAt || null;
  //       return { ...acc, [scope]: { value: valueForPlan, unlocksAt } }
  //     }, {});
  //   permissions.planLevel = planLevel;
  //   return permissions;
  // }, [planLevel, layoutKey, profileListKey]);
}



export const permissions = {
  basic: {
    history_max_years: 1,
    gap_stats_max_years: MAX_YEARS,
    history_max_years__has_upgrade: PLAN_LEVELS.pro,
    gap_stats_max_years__has_upgrade: false,
    scanner_seconds: 5, // UNUSED
    history_download_max_columns: 0,
    history_download_max_rows: 0,
    scanner_copy_paste: false,
    toplist_layout_max_scanners: 1,
    toplist_layout_max_watchlists: 1,
    toplist_layout_max_charts: 2,
    toplist_layout_max__has_upgrade: PLAN_LEVELS.pro,
    toplist_layout_default_max_component: 10,
    max_expressions: 1,
    max_expressions__has_upgrade: PLAN_LEVELS.pro,
  },
  pro: {
    history_max_years: MAX_YEARS,
    gap_stats_max_years: MAX_YEARS,
    history_max_years__has_upgrade: false,
    gap_stats_max_years__has_upgrade: false,
    scanner_seconds: 5, // UNUSED
    history_download_max_columns: 0,
    history_download_max_rows: 0,
    scanner_copy_paste: false,
    toplist_layout_max_scanners: Infinity,
    toplist_layout_max_watchlists: Infinity,
    toplist_layout_max_charts: Infinity,
    toplist_layout_max__has_upgrade: false,
    toplist_layout_default_max_component: 10,
    max_expressions: MAX_EXPRESSIONS_LIMIT,
    max_expressions__has_upgrade: false,
  },
  pro_plus: {
    history_max_years: MAX_YEARS,
    gap_stats_max_years: MAX_YEARS,
    history_max_years__has_upgrade: false,
    gap_stats_max_years__has_upgrade: false,
    history_download_max_columns: 60,
    history_download_max_rows: 2000,
    scanner_seconds: 5, // UNUSED
    scanner_copy_paste: true,
    toplist_layout_max_scanners: Infinity,
    toplist_layout_max_watchlists: Infinity,
    toplist_layout_max_charts: Infinity,
    toplist_layout_max__has_upgrade: false,
    toplist_layout_default_max_component: 10,
    max_expressions: MAX_EXPRESSIONS_LIMIT,
    max_expressions__has_upgrade: false,
  },
  test: {
    history_max_years: MAX_YEARS,
    gap_stats_max_years: MAX_YEARS,
    history_max_years__has_upgrade: false,
    gap_stats_max_years__has_upgrade: false,
    history_download_max_columns: 60,
    history_download_max_rows: 2000,
    scanner_seconds: 5, // UNUSED
    scanner_copy_paste: true,
    toplist_layout_max_scanners: Infinity,
    toplist_layout_max_watchlists: Infinity,
    toplist_layout_max_charts: Infinity,
    toplist_layout_max__has_upgrade: false,
    toplist_layout_default_max_component: 10,
    max_expressions: MAX_EXPRESSIONS_LIMIT,
    max_expressions__has_upgrade: false,
  }
};


let userDataUploadedTime = 0;
const shouldAttachUserDocument = () => {
  const should = (+ Date.now()) - userDataUploadedTime > 60;
  if (should) {
    userDataUploadedTime = + Date.now();
  }
  return should;
}


const logSentry = (msg, { user = {}, planLevel, scope, scopes, selectedPermission }) => {
  Sentry.withScope(sentryScope => {
    sentryScope.setTransactionName('useUserPlanPermissions permissions error');
    sentryScope.setExtras({ planLevel, scope, scopes, selectedPermission });
    if (shouldAttachUserDocument()) {
      userDataUploadedTime = + Date.now();
      const filename = user?.userSub ? `${user?.userSub}.json` : 'unknown.json';
      sentryScope.addAttachment({
        data: JSON.stringify(user),
        contentType: 'application/json',
        filename
      });
    }
    Sentry.captureException(new Error(msg))
  })
}


const urlParams = new URLSearchParams(window.location.search);
const LOCAL_ASSUME_BASIC_PLAN = urlParams.get('assumeBasicPlan') === 'true';

if (process.env.REACT_APP_USERS_LAMBDA_STAGE !== 'prod' && LOCAL_ASSUME_BASIC_PLAN) {
  console.debug('-- ASSUMING BASIC PLAN PERMISSIONS --')
  permissions.pro_plus = permissions.basic;
  Object.keys(profilePermissions).forEach(ctx => {
    Object.keys(profilePermissions[ctx]).forEach(profileListKey => {
      Object.keys(profilePermissions[ctx][profileListKey]).forEach(scope => {
        if (profilePermissions[ctx][profileListKey][scope].basic) {
          profilePermissions[ctx][profileListKey][scope].pro_plus = profilePermissions[ctx][profileListKey][scope].basic;
        }
      })
    })
  });
}


export function usePlanLevel() {
  const user = useSelector(state => state.account.user);
  const lastPlanLevel = useRef();

  return useMemo(() => {
    let planLevel;
    const subscription = new Subscription(user, user.subscriptionId);

    if (user.betaAccess && process.env.REACT_APP_USERS_LAMBDA_STAGE) {
      planLevel = 'pro_plus'
    } else {
      planLevel = subscription.plan_level;
    }

    if (lastPlanLevel.current !== planLevel) {
      lastPlanLevel.current = planLevel;
      if (planLevel) {
        Sentry.setTag('plan.level', planLevel);
      }
    }

    return planLevel;
  }, [user]);
}


function useUserPlanPermissions(scopes) {
  const planLevel = usePlanLevel();

  return useMemo(() => {
    const validPermissions = {};

    const selectedPermission = permissions?.[planLevel];

    if (!selectedPermission) {
      logSentry('useUserPlanPermissions error, permission not found', { planLevel, scopes, selectedPermission });
      throw Error(`ett permissions error, permission not found for ${scopes} ${planLevel}`)
    }

    scopes.forEach(scope => {
      if (!(scope in selectedPermission)) {
        logSentry('useUserPlanPermissions error, scope not allowed', { planLevel, scopes, scope, selectedPermission });
        throw Error(`ett permissions error, scope not allowed: ${scope}`)
      } else {
        validPermissions[scope] = selectedPermission[scope];
      }
    })


    return { planLevel, ...validPermissions };

  }, [planLevel, ...scopes]);
}


export default useUserPlanPermissions;



/*


problem 2

Problem 1: UI.
  - Rewire EditLocked to show modal when:
    - Copy icon clicked
    - Copy/Edit/New clicked

    How we do this? I hate using pure onClick overriding, it sucks
    Maybe we really do need a uiState slice in the reducer. Then maybe
    it can intercept the actions and show a modal.

    Another option is FormAction API 

    This would totally ruin the EditLocked logic though.

  - Ideally a GIF or something would be good

  - How do we smartly swap out the modal content? Do we even do that? Or two seperate elements?
 
  - How do we designate content at the root level? Hardcoding is dumb here.


Problem 2: Profile state

a) What happens when a user downgrades?
  - I think we have to keep the profile, but not allow:
      - Editing
      - Activating

b) What happens when a user is currently on a Custom profile during downgrade?
  - Well fuck me, we have to map the reducer state. forAllComponents(id => defaultId)
  - Where do we do that? What component listens for changes? userPlanPermissions?

  - Accomplishing this really sucks. Its another deep recursion into the layout state

*/
