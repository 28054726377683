/**
 * @param {Function} asyncFn - The async function to execute.
 * @param {number} retries - Number of retries.
 * @param {number} delay - Delay between retries in milliseconds.
 * @returns {Promise<any>} - Returns the resolved value of the async function.
 * @throws {Error} - Throws the last error if all retries fail.
 */
export async function retryAsync(asyncFn, retries = 3, delay = 250) {
  let lastError;

  for (let attempt = 1; attempt <= retries; attempt++) {
    console.log('ATTEMPT', attempt);
    try {
      return await asyncFn();
    } catch (error) {
      lastError = error;
      console.warn(`Attempt ${attempt} failed. Retrying in ${delay}ms...`);
      await new Promise(res => setTimeout(res, delay)); // Wait before retrying
    }
  }

  // If all retries fail, throw the last error
  throw new Error(`All ${retries} retries failed. Last error: ${lastError.message}`);
}
