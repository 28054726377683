import { storageKey } from '../layout/topListLayoutActions';
import edgeUsersApi from 'src/apis/edgeUsersApi';
import { PROFILE_CONFIG } from "./historyPageIntermediateSchema";
import { HARD_CODED_COMPONENTS } from './historyPageIntermediateSchema';
import { STORE_KEY } from './historyPageIntermediateActions';
import { PREDEF_PREFIX } from '../profileSettings/profileSettingsConfig';
import LayoutSyncMiddleware from './layoutSyncMiddleware/layoutSyncMiddleware';
import PromiseHelper from 'src/utils/PromiseHelper';

/** @typedef {import('./layoutSyncMiddleware/constants.js').ChangesetResponse} ChangesetResponse */
/** @typedef {import('./layoutSyncMiddleware/constants.js').LayoutResponse} LayoutResponse */
/** @typedef {import('./layoutSyncMiddleware/constants.js').ChangeItem} ChangeItem */
/** @typedef {import('./layoutSyncMiddleware/constants.js').RevisionRule} RevisionRule */
/** @typedef {import('./layoutSyncMiddleware/constants.js').ReadRule} ReadRule */

const isProfilePredefined = profile => {
  return profile && (profile.predefined || profile.id.startsWith(PREDEF_PREFIX));
}


/**
 * When initializing, add predefined records
 * @param {string} profileListKey
 * @param {object} records
 * @returns {object}
 */
const addPredefinedProfiles = (profileListKey, records) => {
  const cfg = Object.values(PROFILE_CONFIG).find(c => c.listKey === profileListKey) || {};
  const predefined = cfg.predefinedProfiles || [];
  return predefined.reduce((acc, curr) => {
    return { ...acc, [curr.id]: { ...curr } };
  }, records);
}


/**
 * When writing, remove orderIds from record if its parent profile is predefined
 * @param {string} profileListKey
 * @param {string} orderId
 * @param {object} state
 * @returns {boolean}
 **/
const filterPredefinedOrderings = (profileListKey, orderArray, state) => {
  const cfg = Object.values(PROFILE_CONFIG).find(c => c.listKey === profileListKey) || {};
  return orderArray.filter(id => {
    const isPredef = cfg.predefinedProfiles?.some(p => p.id === id) || isProfilePredefined(state.profiles?.[profileListKey]?.[id]);
    return !isPredef;
  });
}

/**
 * When reading, add predefined orderings
 * @param {string} profileListKey
 * @param {string[]} orderArray
 * @param {object} state
 * @returns {string[]}
 **/
const addPredefinedOrderings = (profileListKey, orderArray, state) => {
  const cfg = Object.values(PROFILE_CONFIG).find(c => c.listKey === profileListKey) || {};
  const predefined = cfg.predefinedProfiles || [];

  return [...predefined.map(p => p.id), ...orderArray];
};


/**
 * @type {Object.<string, ReadRule>} 
 * Defines how we modify records when reading from the database.
 * Note: Probably called on the Parent of the target record, so we can modify the entire collection.
 */
const READ_RULES = {
  'profiles.+': {
    // Called on parent of profile record, so we can add records
    mapRecord: ({ pathParts, record }) => addPredefinedProfiles(pathParts[1], record),
  },
  'orderings.profiles.+': {
    // Called on same child record, because its an array already.
    mapRecord: ({ pathParts, record: orderArray, state }) => addPredefinedOrderings(pathParts[2], orderArray, state),
  }
}


/** 
 * @type {Object.<string, RevisionRule>} 
 * Defines how we:
 *  1) Modify records before saving (localstorage or db)
 *  2) Build a revision changeset when sending to db
 *  3) What changes get a revision bump / sent to BroadcastChannel
 */
const REVISION_RULES = {
  'components.+': {
    getId: ({ pathParts }) => pathParts[1],
    getRecordType: ({ pathParts }) => pathParts[0],
    add: true,
    delete: true,
    put: false
  },
  'profiles.+.+': {
    getId: ({ pathParts }) => pathParts[2],
    getRecordType: ({ pathParts }) => `${pathParts[0]}#${pathParts[1]}`,
    filterRecord: ({ record }) => !isProfilePredefined(record),
    add: true,
    delete: true,
    put: true // Maybe we don't want to communicate profile changes?
  },
  'orderings.profiles.+': {
    getId: ({ pathParts }) => pathParts[2],
    getRecordType: ({ pathParts }) => `${pathParts[0]}#${pathParts[1]}`,
    mapRecord: ({ pathParts, record: orderArray, state }) => filterPredefinedOrderings(pathParts[2], orderArray, state),
    add: true,
    delete: true,
    put: false // Putting an order array might mean adding a new item to it, thus a new profile. But, that MUST already be covered by the profiles rule.
    // You can't add a new key to this array without an ADD action being produced within profiles. Theoretically...
  }
}


/**
 * Send a changeset to the database
 * @param {string} schemaVersion
 * @param {{revision: number, changeset: ChangeItem[] }} changesetReq
 * @returns {Promise<ChangesetResponse>}
 **/
async function persistChangeset(schemaVersion, changesetReq) {
  console.log('[REV_HIST] PERSIST:', changesetReq);
  const { data } = await edgeUsersApi.post(`/user/revisionedlayouts/${schemaVersion}/${STORE_KEY}`, changesetReq);
  return data;
}


/**
 * Initialize our layout from the database
 * @param {string} schemaVersion
 * @returns {Promise<LayoutResponse>}
 **/
async function fetchLayout(schemaVersion) {
  const { data } = await edgeUsersApi.get(`/user/revisionedlayouts/${schemaVersion}/${STORE_KEY}`);
  console.log('[REV_HIST] FETCH END:', data);
  return data;
}


let middlewareApi = null;

/**
 * Singleton factory. Use getHistoryPageIntermediateMiddleware().middleware to apply to redux.
 * @returns {LayoutSyncMiddleware} 
 */
const getHistoryPageIntermediateMiddleware = () => {
  if (!middlewareApi) {
    middlewareApi = new LayoutSyncMiddleware({
      schemaVersion: 'v0',
      namespace: STORE_KEY,
      ignorePaths: ['isFetching', 'ui'],
      revisionConfig: REVISION_RULES,
      readConfig: READ_RULES,
      persistChangeset,
      fetchLayout,
      actionMatcher: action => action.type.startsWith(`@${STORE_KEY}/`)
    });
  }

  return middlewareApi
}

export default getHistoryPageIntermediateMiddleware;
