import React, { useState, useMemo, useRef, useContext, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import _isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from '@ag-grid-community/react';
import LayoutContext from '../layout/LayoutContext';
import { useDeepCompareEffect } from 'src/hooks/useDeepCompare';
import useToplistLinkedValues from 'src/hooks/useToplistLinkedValues';
import { generateSimpleFilenameVerson } from 'src/utils/generateProfileFilenameVersion';
import IntercomArticleButton, { INTERCOM_SLUGS, INTERCOM_ARTICLES } from 'src/app/components/intercom/IntercomArticleButton';
import {
  applyResize,
  handleKeyboardRowNavigation,
  onRowSelected
} from 'src/utils/agGridFunctions';
import { GRID_COLUMNS } from 'src/app/components/grid/topListScanner/columns/columnDefs';
import {
  rowClassRules,
  buildGridColumns,
} from 'src/app/components/grid/buildColumns';
import {
  PROFILE_CONFIG,
  WATCHLIST_MANUAL_ORDER
} from 'src/redux/layout/topListLayoutSchema';
import useRealtimeMappedExpressions from 'src/app/components/grid/topListScanner/columns/useRealtimeMappedExpressions';
import useFilterRelevantExpressions from 'src/app/slicedForm/shared/hooks/useFilterRelevantExpressions';
import {
  intIdExists,
  addWatchlistTicker,
  fetchWatchlists,
  reorderWatchlistTickers,
  createWatchlist
} from 'src/redux/layout/topListLayoutActions';
import {
  selectComponent,
  selectProfileList,
  selectWatchlistData,
} from 'src/redux/layout/topListLayoutSelectors';
import {
  updateComponent,
  updateScannerColumnProfiles,
} from 'src/redux/layout/topListLayoutActions';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useDefaultContextMenu from 'src/app/components/grid/contextMenu/useDefaultContextMenu';
import WatchlistLoadingCellFullWidth from 'src/app/components/grid/cellRenderers/WatchlistLoadingCellFullWidth';
import CopyDefaultProfileModal from 'src/app/components/elements/CopyDefaultProfileModal';
import AnimateChangeCellRenderer from 'src/app/components/grid/cellRenderers/AnimateChangeCellRenderer';
import CompactNumberCell from 'src/app/components/grid/cellRenderers/CompactNumberCell';
import TopListSlicedColumnsForm from 'src/app/TopListsMosaic/TopListScanner/forms/SlicedColumnsForm';
import MosaicPanel from 'src/app/TopListsMosaic/layout/MosaicPanel';
import MosaicPanelHeader from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/MosaicPanelHeader';
import MosaicPanelBody from 'src/app/TopListsMosaic/layout/MosaicPanelBody';
import SmallFilterWindow from 'src/app/components/filterContainers/SmallFilterWindow';
import ManageWatchlistsMenu from 'src/app/TopListsMosaic/TopListWatchlist/ManageWatchlistsMenu';
import {
  WatchlistIcon,
  PlusIcon2,
  moveIconContent
} from 'src/theme/EdgeIcons';
import {
  makeStyles,
  useTheme
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  gridContainer: {
    flex: 1,
    '& .ag-root-wrapper': {
      borderRadius: '0 !important'
    }
  },
  filterWindowFormControl: {
    padding: '7px 0',
  },
  datePicker: {
    '& .MuiInputBase-input': {
      maxWidth: 122
    }
  },
  manageLayoutsPopover: {
    '& .MuiPopover-paper': {
      backgroundColor: theme.palette.background.paperAlt,
      borderRadius: 0
    }
  },
  filterWindow: {
    height: '100%'
  },
}));

/**
  * Instead of useSyncronizedTimer, we fetch in MoasicRoot.
  * This is because all watchlists share a single db call.
  **/
function TopListWatchlist({ className }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { componentId, layoutId } = useContext(LayoutContext);
  const {
    gridColumnSizeKey,
    order = 'desc',
    orderby = WATCHLIST_MANUAL_ORDER,
    cellFlashDisabled = false,
    [PROFILE_CONFIG.SCANNER_COLUMNS.idKey]: columnProfileId,
    [PROFILE_CONFIG.WATCHLIST_ROWS.idKey]: watchlistProfileId
  } = useSelector(selectComponent(componentId, layoutId));

  const [expressions, expressionPayload] = useRealtimeMappedExpressions();
  const { dispatchUpdateLinkedData } = useToplistLinkedValues();
  const [ticker, setTicker] = useState(null);

  const [isFetchingItem, setIsFetchingItem] = useState(true);
  const [showCopyDefaultProfileModal, setShowCopyDefaultProfileModal] = useState(false);
  const columnProfilesList = useSelector(selectProfileList(PROFILE_CONFIG.SCANNER_COLUMNS.listKey));
  const columnProfile = columnProfilesList.find(p => p.id === columnProfileId);
  const watchlistProfileList = useSelector(selectProfileList(PROFILE_CONFIG.WATCHLIST_ROWS.listKey));
  const watchlistProfile = watchlistProfileList.find(p => p.id === watchlistProfileId);
  const watchlistData = useSelector(selectWatchlistData);

  const relevantExpressions = useFilterRelevantExpressions(expressions, { columnProfile, orderby });


  const itemIdOrder = useRef([]);
  const isMounted = useIsMountedRef();
  const gridRef = useRef();
  const gridReadyRef = useRef(false);


  const handleSetResizeKey = useCallback((resizeKey) => {
    applyResize(gridRef, resizeKey);
    dispatch(updateComponent(componentId, layoutId, {
      gridColumnSizeKey: resizeKey
    }));
  }, [componentId, layoutId]);

  const setCellFlashDisabled = useCallback((newValue) => {
    dispatch(updateComponent(componentId, layoutId, {
      cellFlashDisabled: !!newValue
    }));
  }, [componentId, layoutId])

  const makeWatchlistMenu = useDefaultContextMenu({
    handleSetResizeKey,
    setCellFlashDisabled
  });

  const handleCloseDefaultProfile = () => {
    setShowCopyDefaultProfileModal(false);
  };

  const handleCopyDefaultProfile = (watchlistProfile) => {
    const newName = generateSimpleFilenameVerson(watchlistProfile.profileName, watchlistProfileList.map(({ profileName }) => profileName));
    dispatch(createWatchlist(componentId, layoutId, newName, watchlistProfile.id));
    setIsFetchingItem(true);
    setShowCopyDefaultProfileModal(false);
  };


  const rowData = useMemo(() => {
    if (gridReadyRef.current) {
      setIsFetchingItem(false);
    }
    if (!watchlistData || !intIdExists(watchlistProfileId)) return [];

    setTimeout(() => {
      if (isMounted.current && gridRef?.current?.api) {
        gridRef?.current?.api?.refreshCells({
          columns: ['index'],
          suppressFlash: true
        });
      }
    }, 0)

    return watchlistData
      .filter(row => row.watchlist_id === watchlistProfileId)
      .sort((a, b) => a.row_order - b.row_order);
  }, [watchlistData, watchlistProfileId]);


  const scannerColumns = useMemo(() => {
    // We set sort here for the initial load. The grid needs to be told what sort it starts with.
    // After that, the grid will then handle its sort internally. We will capture the value inside the dataSource,
    // and save it to the component, so we can set the sort on the next load.

    // If this memo fires again in the same component, it's fine if we set the sort here again. Not needed, but it doesn't hurt.
    const gridColumns = buildGridColumns(columnProfile.columns, GRID_COLUMNS, relevantExpressions);
    const indexColIdx = gridColumns.findIndex(c => c.field === 'index');
    if (indexColIdx !== -1) {
      gridColumns[indexColIdx].rowDrag = true;
    }
    const sortColIdx = gridColumns.findIndex(c => c.field === orderby);
    if (sortColIdx !== -1) {
      // we might be sorting by MANUAL_ORDER, which is not a column in the grid.
      gridColumns[sortColIdx].sort = order;
    }
    return gridColumns;
  }, [columnProfile, relevantExpressions]);


  useDeepCompareEffect(() => {
    if (gridReadyRef.current) {
      setIsFetchingItem(true);
      dispatch(fetchWatchlists());
    }
  }, [columnProfile.columns, watchlistProfileId, relevantExpressions]);


  const handleColumnProfileSubmit = useCallback(({ expressions, ...profile }) => {
    dispatch(updateScannerColumnProfiles(profile, layoutId, componentId, expressionPayload(expressions)));
  }, [layoutId, componentId]);


  const handleRowClick = useCallback(({ data }) => {
    if (data) {
      dispatchUpdateLinkedData({
        ticker: data.ticker,
        ssr: data.ssr,
        historicalDate: false
      });
    }
  }, [dispatchUpdateLinkedData]);


  const handleAddTickerToList = (ticker) => {
    if (watchlistProfile && watchlistProfile.predefined) {
      setShowCopyDefaultProfileModal(true);
    } else {
      dispatch(addWatchlistTicker(watchlistProfileId, ticker));
    }
    setTicker(null);
  };


  const handleReorderDragStart = useCallback(({ api }) => {
    itemIdOrder.current = [];
    api.forEachNode(node => itemIdOrder.current.push(node.data.item_id));
  }, []);


  const handleReorderTicker = useCallback(({ api }) => {
    const newItemIds = [];
    api?.forEachNode(node => newItemIds.push(node.data.item_id));

    if (!_isEqual(itemIdOrder.current, newItemIds)) {
      if (watchlistProfile && watchlistProfile.predefined) {
        setShowCopyDefaultProfileModal(true);
      } else {
        dispatch(reorderWatchlistTickers(watchlistProfileId, {
          order: newItemIds
        }));
      }
    }
  }, [watchlistProfile?.tickers, watchlistProfile?.id]);


  const handlePersistSort = useCallback(({ api, columnApi }) => {
    if (isMounted.current && api) {
      api?.refreshCells({
        columns: ['index'],
        suppressFlash: true
      });
    }

    const sortCol = columnApi.getColumnState().find(s => s.sort != null);

    const newOrderby = sortCol?.colId || WATCHLIST_MANUAL_ORDER;
    const newOrder = sortCol?.sort || 'desc';

    if (newOrder !== order || newOrderby !== orderby) {
      dispatch(updateComponent(componentId, layoutId, {
        order: newOrder,
        orderby: newOrderby
      }));
    }
  }, [order, orderby]);


  const getRowId = useCallback(({ data }) => data.item_id, []);


  const onGridReady = useCallback(() => {
    gridReadyRef.current = true;
    dispatch(fetchWatchlists());
  }, []);


  const icons = useMemo(() => ({
    rowDrag: `<span class="ett-row-drag-hack-icon">${moveIconContent}</span>`
  }));


  const fullWidthCellRenderer = useMemo(() => WatchlistLoadingCellFullWidth, []);


  const isFullWidthRow = useCallback(({ rowNode }) => {
    return rowNode?.data?.loadingRow;
  }, []);


  const popupParent = useMemo(() => {
    return document.querySelector('body');
  }, []);


  return (
    <MosaicPanel className={clsx(className, classes.root)}>
      <CopyDefaultProfileModal
        description={'You cannot edit a default watchlist. Make an editable copy?'}
        profileName={'Watchlist'}
        open={showCopyDefaultProfileModal}
        onClose={handleCloseDefaultProfile}
        onCopy={() => handleCopyDefaultProfile(watchlistProfile)}
      />
      <MosaicPanelHeader
        tickerSearchDoNotShowLastTicker
        tickerSearchIcon={PlusIcon2}
        tickerSearchPlaceholder={'Add'}
        tickerSearchValue={ticker}
        onTickerSearchSubmit={handleAddTickerToList}
        loading={isFetchingItem}
        titleSuppliment={watchlistProfile && watchlistProfile?.profileName}
        titleSupplimentColor={theme.palette.text.secondary}
        align="center"
      >
        <IntercomArticleButton
          // articleId={INTERCOM_ARTICLES?.toplist?.components?.[COMPONENT_TYPES.WATCHLIST]}
          articleSlug={INTERCOM_SLUGS?.toplist?.components?.watchlist}
        />
        <TopListSlicedColumnsForm
          profiles={columnProfilesList}
          activeProfile={columnProfileId}
          expressions={expressions}
          onSubmit={handleColumnProfileSubmit}
        />
        <SmallFilterWindow
          className={classes.filterWindow}
          popoverClassName={classes.manageLayoutsPopover}
          Icon={WatchlistIcon}
          noGutters
          popoverMinWidth={200}
          verticalAnchor="top"
          iconText={'Watchlists'}
          shouldHideIconText
          shouldRenderProps
        >
          {(handleClose) => (
            <ManageWatchlistsMenu
              layoutId={layoutId}
              componentId={componentId}
              watchlistProfileId={watchlistProfileId}
              handleClose={handleClose}
              setIsFetching={setIsFetchingItem}
            />
          )}
        </SmallFilterWindow>
      </MosaicPanelHeader>
      <MosaicPanelBody
        loading={isFetchingItem}
        className={clsx(classes.gridContainer, 'ag-theme-ett', 'ag-theme-no-row-selection')}
      >
        <AgGridReact
          context={{
            watchlistProfileId,
            cellFlashDisabled,
            expressions
          }}
          ref={gridRef}
          rowData={rowData}
          columnDefs={scannerColumns}
          rowSelection={'single'}
          rowDragManaged={true}
          popupParent={popupParent}
          onSortChanged={handlePersistSort}
          onDragStarted={handleReorderDragStart}
          onRowDragEnd={handleReorderTicker}
          onRowSelected={(params) => onRowSelected(params, handleRowClick)}
          onRowClicked={handleRowClick}
          navigateToNextCell={handleKeyboardRowNavigation}
          suppressMultiSort={true}
          animateRows={true}
          headerHeight={25}
          getContextMenuItems={makeWatchlistMenu}
          rowClassRules={rowClassRules}
          getRowId={getRowId}
          sortingOrder={['desc', 'asc', 'none']}
          rowModelType="clientSide"
          isFullWidthRow={isFullWidthRow}
          loadingCellRenderer={() => <span />}
          icons={icons}
          fullWidthCellRenderer={fullWidthCellRenderer}
          components={{
            'animateChangeCellRenderer': AnimateChangeCellRenderer,
            'compactNumberCellRenderer': CompactNumberCell,
          }}
          onGridReady={onGridReady}
          onFirstDataRendered={() => {
            if (gridColumnSizeKey) {
              applyResize(gridRef, gridColumnSizeKey);
            }
          }}
        />
      </MosaicPanelBody>
    </MosaicPanel>
  );
}

export default TopListWatchlist;
