import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { MergeType } from '@material-ui/icons';
import MarketingModal from 'src/app/components/MarketingModal/elements/MarketingModal';
import MarketingModalCallout from 'src/app/components/MarketingModal/elements/MarketingModalCallout';
import {
  Typography,
  List,
  ListItem,
  Box,
  ListItemText,
  ListItemIcon,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { CheckIcon } from 'src/theme/EdgeIcons';
import { permissions, PLAN_LEVEL_LABELS } from 'src/hooks/useUserPlanPermissions';
import MarketingModalErrorBoundary from './ErrorBoundaryMarketingModal';


const useStyles = makeStyles(theme => ({
  root: {},
}));



function MarketingModalTickerStatsAggregatesUpgrade(props) {
  return (
    <MarketingModalErrorBoundary {...props}>
      <ModalContent {...props} />
    </MarketingModalErrorBoundary>
  )
}


function ModalContent({
  className,
  suggestedPlanLevel,
  title = 'Ticker Stats',
  onClose
}) {
  const theme = useTheme();
  const classes = useStyles();
  const planLevelLabel = PLAN_LEVEL_LABELS[suggestedPlanLevel];

  const titleEl = (
    <Typography variant="h2">
      <span style={{ marginRight: 10 }}>🚀</span>
      Upgrade to <span style={{ color: theme.palette.text.planTag }}>{planLevelLabel}</span>
    </Typography>
  )

  return (
    <MarketingModal
      className={clsx(classes.root, className)}
      suggestedPlanLevel={planLevelLabel}
      titleContent={titleEl}
      onClose={onClose}
    >

      <MarketingModalCallout mt={3} Icon={MergeType}>
        <Typography variant="h3">Customizable Ticker Stats</Typography>
        <Typography variant="body1">
          Customize {title} with personalized profiles—access averages, sums, standard deviations, and more, tailored to your needs
        </Typography>
      </MarketingModalCallout>

      <Box pt={2}>
        <Typography variant="body1" color="textSecondary">
          Additional {planLevelLabel} Features:
        </Typography>
      </Box>

      <Box pt={2} pb={1}>
        <List>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary={`${permissions?.[suggestedPlanLevel]?.history_max_years} Years of Historical Data`} />
          </ListItem>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary="Unlimited Scanners, Charts, and Watchlists" />
          </ListItem>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary={`Up to ${permissions?.[suggestedPlanLevel]?.max_expressions} custom Expressions`} />
          </ListItem>
        </List>
      </Box>

    </MarketingModal>
  )

}


MarketingModalTickerStatsAggregatesUpgrade.propTypes = {
  className: PropTypes.string,
  suggestedPlanLevel: PropTypes.string,
  onClose: PropTypes.func,
}



export default MarketingModalTickerStatsAggregatesUpgrade;
