import React from 'react';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { usePopupState, bindHover, bindPopover } from 'material-ui-popup-state/hooks';



function TableCellHoverMenu({ className, id, Menu, children, paddingClass }) {
  const popupState = usePopupState({ variant: 'popover', popupId: id });

  return (
    <div className={className}>
      <div {...bindHover(popupState)} className={paddingClass} style={{display: 'inline-block'}}>
        {children}
      </div>
      <HoverPopover
        {...bindPopover(popupState)}
        transitionDuration={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div>
          {Menu}
        </div>
      </HoverPopover>
    </div>
  );
}


export default TableCellHoverMenu;
