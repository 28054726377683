
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { stagedApis } from 'src/constants';
import { BrowserTracing } from '@sentry/react';
import { SettingsProvider } from 'src/context/SettingsContext';
import { restoreSettings } from 'src/utils/settings';
import Root from 'src/Root';
import App from 'src/App';
import { TagManagerService } from 'src/services/tagManagerService';
const stage = process.env.REACT_APP_USERS_LAMBDA_STAGE;
const usersBaseUrl = stagedApis.edgeUsersApi;


const shouldUseOffline = () => {
  return stage === 'prod';
}

Sentry.init({
  dsn: 'https://0a37fe7dba28419bbb69e6d4369413c4@o1275128.ingest.sentry.io/6470108',
  tunnel: `${usersBaseUrl}/tunnel`,
  integrations: [new BrowserTracing()],
  ...(shouldUseOffline() && { transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport) }),
  tracesSampleRate: stage === 'prod' ? 1 : .1,
  environment: stage,
  maxBreadcrumbs: 40,
  normalizeDepth: 6,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Error: [e: Connection closed; statusCode=400; code=80017',
    'NotAllowedError: play() failed',
    'Non-Error exception captured with keys: cause, code, href, message',
    'ResizeObserver loop completed with undelivered notifications.',
    'The error you provided does not contain a stack trace.',
    "Cannot read properties of undefined (reading 'getFirstVirtualRenderedRow')",
    "[Violation] 'setInterval' handler took",
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'ett permissions error,',
    'AxiosError: Request aborted',
    'ett permissions error,',
    'AxiosError: Network Error',
    'Client configured authentication provider request failed',
    'Not implemented on this platform'
  ],
  denyUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ]
});


TagManagerService.initialize({ gtmId: 'GTM-PLBZ28D' });


const settings = restoreSettings();

// serviceWorker.register();

ReactDOM.render(
  <Root settings={settings}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </Root>,
  document.getElementById('root')
);

// serviceWorker.unregister();
