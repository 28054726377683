import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isInteger from 'lodash/isInteger';
import _isObject from 'lodash/isObject';
import AsyncQueue from './HistAsyncQueue';
import requestIdleCallback from 'ric-shim';
import { Store } from 'redux';
import { retryAsync } from './helpers';
import { INIT_SOURCE } from './constants';
import {
  buildChangeset,
  buildInitializationChangeset,
  applyLocalstorageWriteModifications,
  applyInitializationReadModifications
} from './buildChangeset';
import { createErrorNotification } from 'src/redux/notifications/notificationActions';


/** @typedef {import('./layoutSyncMiddleware/constants.js').ChangesetResponse} ChangesetResponse */
/** @typedef {import('./layoutSyncMiddleware/constants.js').LayoutResponse} LayoutResponse */
/** @typedef {import('./layoutSyncMiddleware/constants.js').RevisionRule} RevisionRule */
/** @typedef {import('./layoutSyncMiddleware/constants.js').StoredValue} StoredValue */
/** @typedef {import('./layoutSyncMiddleware/constants.js').MessageEvent} MessageEvent */


const ALLOW_STORAGE_OVERRIDE = process.env.REACT_APP_USERS_LAMBDA_STAGE === 'local';


/**
 * Reusable middleware to manage Layouts. It:
 *  - Initializes state from either localstorage or database when prompted
 *    via INIT action. 
 *    - Applies mappings based on RevisionRule[].readModifications before loading
 *
 *  - Listens for actions that match a provided actionMatcher, and on match:
 *    - Uses prevState and nextState to build a diff to send to SERVER
 *
 *  - On successful save:
 *    - communicates the original action to other tabs via BROADCAST
 *    - Persists the entire redux state to LOCALSTORAGE
 *      - local state will be be mapped by RevisionRule[].writeModifications
 *
 *  REVISION RESOLUTION:
 *  In order to prevent invalid states from being reached, all updates must
 *  first validate their current revision against the incomming.
 *
 *  As specified in RevisionRules, some changes will BUMP a revision, some will not.
 *
 *  REVISIONED CHANGES: means its a breaking change. It must be communcated to other tabs for the 
 *  website to work correctly. If any change in a changeset requires a revision, then they all do. 
 *  There's only 1 revsision accross all records.
 *
 *  Rules for bumping:
 *    - If we're sending a bump to server, our current revision MUST match the server's revision.
 *      - On success, we return the new bumped revision, and set our browser's revision to match
 *      - On fail, our frontend is now considered out-of-sync. It can no longer persist changes, 
 *        and must be reloaded. On reloaded, we will fetch the freshest server revision instead.
 *    - If a server bump was successful, we must also BROADCAST to other tabs:
 *      - The other tab MUST be 1 revision behind the incomming revision. IF not, the other tab is now out-of-sync.
 *        Same rules apply. It will no longer be able to save.
 *    - We also now write the new state to localstorage, from the original tab.
 *
 *  Through this scheme, one tab will always win out, there will be no race conditions. Theoretically they will 
 *  stay perfectly in-sync through BROADCAST, and both can freely make changes. However, getting out-of-sync is fine,
 *  it will not break anything. Just prevent saves until reload.
 *
 *  NON-REVISIONED CHANGES:
 *  These changes can diverge between two tabs. Like changing sort on a grid, or width of components. 
 *
 *  They will still be sent to DB, and the DB will still validate the current revision matches the DB revision. 
 *  This is necissary so that we ensure all non-revision operations on revisioned resources are valid. E.G:
 *    - ADD component '123' (revision)
 *    - UPDATE component '123' (non-revision) - but still requries previos revision to exist.
 *
 *  These changes MUST NOT be broadcast. Otherwise a user cannot make divergant changes.
 *
 *  These changes WILL be saved to localstarge. last-write-wins between tabs. Totally fine.
 *
 **/
export default class LayoutSyncMiddleware {
  /**
   * @param {object} options
   * @param {string} options.namespace
   * @param {string} options.schemaVersion
   * @param {{string: RevisionRule}} options.revisionConfig
   * @param {{string: ReadRule}} options.readConfig
   * @param {function} options.persistChangeset
   * @param {function} options.fetchLayout
   * @param {function(object): boolean} options.actionMatcher
   * @param {string[]} [options.ignorePaths]
   * @param {function} [options.getUser]
   * @param {function} [options.getUserSub]
   */
  constructor({
    namespace,
    schemaVersion,
    revisionConfig,
    readConfig,
    persistChangeset,
    actionMatcher,
    fetchLayout,
    ignorePaths = [],
    getUser = state => state.account.user,
    getUserSub = state => state.account.user.userSub,
  }) {
    if (!namespace || !schemaVersion || !revisionConfig || !readConfig || !actionMatcher || !persistChangeset || !fetchLayout) {
      throw new Error('LayoutSyncMiddleware requires namespace, schemaVersion, revisionConfig, persistChangeset, and fetchLayout');
    }

    /** @type {string} */
    this.namespace = namespace;
    /** @type {string} */
    this.schemaVersion = schemaVersion;
    /** @type {{string: RevisionRule}} */
    this.revisionConfig = revisionConfig;
    /** @type {{string: ReadRule}} */
    this.readConfig = readConfig;
    /** @type {function(string, object): Promise<ChangesetResponse>} */
    this.persistChangeset = persistChangeset;
    /** @type {function(string): Promise<LayoutResponse>} */
    this.fetchLayout = fetchLayout;
    /** @type {function(object): boolean} */
    this.actionMatcher = actionMatcher;

    /** @type {string[]} */
    this.ignorePaths = ignorePaths;
    /** @type {function(object): object} */
    this.getUser = getUser;
    /** @type {function(object): string} */
    this.getUserSub = getUserSub;

    /** 
     * @type {boolean}
     * If we have conflics, we will need to mark one Tab as stale and prevent further DB/localstorage changes *
     * This is not ideal, but all other solutions require locks, commits/rollbacks, or conflict resolution.
     */
    this.isStale = false;

    this.initialized = false;
    this.bc = null;
    this.currentRevision = -1; // has to be tracked manually, cannot exist inisde redux
    this.asyncQueue = new AsyncQueue();

    // Will be set during initialization. Must exist before we do anything.
    this.userSub = null;
  }

  _isInitAction = action => {
    const parts = action.type.split('/');
    return parts?.[1] === 'INIT';
  }

  _getInitType = action => {
    const parts = action.type.split('/');
    return parts?.[2];
  }

  _getStorageKey = () => {
    if (!this.userSub) {
      throw new Error('LayoutSyncMiddleware not initialized');
    }
    return `layoutmdw#${this.userSub}#${this.schemaVersion}#${this.namespace}`;
  }

  _getDatabaseRevisionKey = () => {
    if (!this.userSub) {
      throw new Error('LayoutSyncMiddleware not initialized');
    }
    return `revision#${this.schemaVersion}#${this.namespace}`;
  }

  _getBroadcastChannelName = () => {
    if (!this.userSub) {
      throw new Error('LayoutSyncMiddleware not initialized');
    }
    return `layoutmdw#${this.userSub}#${this.schemaVersion}#${this.namespace}`;
  }

  _removeIgnoredPaths = state => {
    return Object.keys(state).reduce((acc, curr) => {
      if (!this.ignorePaths.includes(curr)) {
        acc[curr] = state[curr];
      }
      return acc;
    }, {});
  }

  /**
   * If storage === db, use storage
   * if storage > db use DB for safety (FUTURE: Just rewrite DB here)
   * if storeage < db, use DB
   * If storage and not DB, use initialState for safety (FUTURE: Just rewrite to DB here)
   * If no storage or DB, use initialState
   * @param {object} state - redux state
   * @returns {{soure: string, state: object, revision: number}}
  **/
  _getInitialStatePayload = async state => {
    console.log(`[LAYOUTMDW ${this.namespace} INIT] initializing state`);

    const user = this.getUser(state);
    const storageKey = this._getStorageKey();

    const dbRevision = user?.[this._getDatabaseRevisionKey()] || -1;
    let storageRevision = -1;

    const namespaceState = state[this.namespace];

    const storedValue = readLocalStorage(this._getStorageKey());
    if (storedValue) {
      const { revision, data } = storedValue;
      storageRevision = revision;
      console.log(`[LAYOUTMDW ${this.namespace} INIT] storage:${storageRevision}, db:${dbRevision}`);

      if (storageRevision === dbRevision || (storageRevision > 0 && dbRevision <= 0 && ALLOW_STORAGE_OVERRIDE)) {
        // storage and DB agree, we can user storage. TODO: if storage > db, persist local.
        console.log(`[LAYOUTMDW ${this.namespace} INIT] storageRevision matches or ALLOWED, load_from_storage`, data);
        return {
          source: INIT_SOURCE.storage,
          state: data,
          revision: storageRevision
        };
      }
    } else {
      console.log(`[LAYOUTMDW ${this.namespace} INIT] storage:${storageRevision}, db:${dbRevision}`);
    }

    if (storageRevision > 0 && dbRevision <= 0) {
      // If storage exists but database revision is missing, we have an issue, reset to initial state TODO: persist local
      console.warn(`[LAYOUTMDW ${this.namespace} INIT] storage exists but dbRevision is missing, reset to initial state`);
      clearLocalStorage(storageKey);
      // TODO: We need to initialize DB too.
      return {
        source: INIT_SOURCE.initialState,
        state: namespaceState,
        revision: 0,
      }
    }

    if (dbRevision <= 0) {
      // We don't have storage and we don't have DB data. Initialize from redux.
      console.log(`[LAYOUTMDW ${this.namespace} INIT] no storage or db, load_from_initialState`);
      // TODO: We need to initialize DB too.
      return {
        source: INIT_SOURCE.initialState,
        state: namespaceState,
        revision: 0,
      }
    }

    // If we get here, we should have DB data and no/invalid storage data.
    try {
      const fetchFnc = async () => await this.fetchLayout(this.schemaVersion);
      const responseData = await retryAsync(fetchFnc, 2, 150);
      const { revision: dbRevision, data: dbData } = responseData;

      if (!dbData
        || !_isObject(dbData)
        || !Object.keys(dbData).length
        || !dbRevision
        || !_isInteger(dbRevision)
        || !dbRevision > 0
      ) {
        throw new Error(`Invalid or empty data recieved from fetchLayout: ${JSON.stringify(responseData)}`);
      }

      // We shouldn't need to apply writeModifications, DB data should already be corrected
      writeLocalStorage(
        storageKey,
        storageRevision,
        dbData,
      );

      console.log(`[LAYOUTMDW ${this.namespace} INIT] load_from_db`, dbData);

      return {
        source: INIT_SOURCE.database,
        state: dbData,
        revision: dbRevision,
      }
    } catch (err) {
      console.error(`[LAYOUTMDW ${this.namespace} INIT] Error fetching layout`, err);
    }

    console.log(`[LAYOUTMDW ${this.namespace} INIT] DB issue and no storage, load_from_initialState`);
    clearLocalStorage(storageKey);

    return {
      source: INIT_SOURCE.initialState,
      state: namespaceState,
      revision: 0,
    }
  }

  /**
   * Create the broadcast channel if it doesn't exist or is the wrong channel name
   * @param {string} channelName
   * @param {Store} store
   **/
  createBroadcastChannel = (channelName, store) => {
    if (!channelName) {
      throw new Error('no channel name provided');
    }
    if (this.bc && this.bc.name === channelName) {
      return this.bc;
    }
    if (this.broadcastchannel) {
      this.bc.close();
    }
    const bc = new BroadcastChannel(channelName);
    bc.onmessage = event => this._onRecieveBroadcastMessage(event, store);
    bc.onmessageerror = event => this._onRecieveBroadcastError(event, store);

    this.bc = bc;
  }

  sendBroadcast = (action, revision) => {
    if (!this.bc) {
      throw new Error('Broadcast channel not initialized');
    }
    this.bc.postMessage({
      ...action,
      source: 'broadcast',
      revision
    });
  }

  /**
   * Called when a message is recieved
   * @param {MessageEvent} event
   * @param {Store} store
   * @returns {void}
   */
  _onRecieveBroadcastMessage = (event, store) => {
    console.log(event);
    console.log(`[LAYOUTMDW ${this.namespace}] Recieved broadcast message`, event.data);

    const action = event.data;
    store.dispatch(action);
  }

  /**
   * Called when the recieved message cannot be deserialized
   * @param {MessageEvent} event
   * @param {Store} store
   */
  _onRecieveBroadcastError = (event, store) => {
    console.log(event)
    console.log(`[LAYOUTMDW ${this.namespace}] Broadcast error`, event?.data);
  }

  /**
   * ENTRY POINT. Apply this to Redux store.
   * @param {Store} store
   * @returns {function}
   */
  middleware = store => next => async action => {
    if (!this.actionMatcher(action)) {
      return next(action);
    }

    if (this._isInitAction(action)) {
      const result = next(action);

      const initType = this._getInitType(action);

      if (initType === 'START') {
        const state = store.getState();
        this.userSub = this.getUserSub(state);

        const initialPayload = await this._getInitialStatePayload(state);

        if (initialPayload.source === INIT_SOURCE.initialState) {
          // Make sure we don't mutate redux state
          initialPayload.state = _cloneDeep(initialPayload.state);

          // Send our initial state to server
          const initialChangeset = buildInitializationChangeset(initialPayload.state, this.revisionConfig);

          try {
            const func = async () => await this.persistChangeset(this.schemaVersion, {
              changeset: initialChangeset,
              revision: initialPayload.revision // Must be zero
            });
            const res = await retryAsync(func, 2, 150);
            if (!res.success || !res.revision) {
              throw new Error('Failed to persist initial state, response invalid', res);
            }
            initialPayload.revision = res.revision;
            console.log(res);
          } catch (err) {
            console.log('panic', err);
            // TODO: I have no clue what to do here. Prevent changes I guess.
            throw new Error('Failed to persist initial state');
          }
        } else {
          this.currentRevision = initialPayload.revision;
        }

        // If first init, then payload.state will just contain the reducer.
        // That should already have all the defaults. But to make life more consistent,
        // we're going to apply these transformations anyways.
        // TODO: I'm aware that this is confusing. Whats the point of the reducer initState then?
        initialPayload.state = applyInitializationReadModifications(initialPayload.state, this.readConfig);

        this.currentRevision = initialPayload.revision;

        store.dispatch({ type: `@${this.namespace}/INIT/SUCCESS`, payload: initialPayload });

        this.createBroadcastChannel(this._getBroadcastChannelName(), store);
        this.initialized = true;
      }

      // all other init types should be ignored. The reducer will handle.
      return result;
    }

    if (!this.initialized) {
      // Not sure what to do here. We should never reach this.
      console.error('MAJOR ISSUE: LayoutSyncMiddleware not initialized, but an action was recieved!', action, this.namespace);
      return;
    }

    if (action.source === 'broadcast') {
      if (this.isStale) {
        console.error(`[LAYOUTMDW ${this.namespace}] Stale state. Ignoring action.`, action);
        return;
      }
      if (action.revision !== this.currentRevision + 1) {
        console.error(`[LAYOUTMDW ${this.namespace}] Received out of order revision. Ignoring.`, action.revision, this.currentRevision);
        return;
      } else {
        // Don't write localstorage. The sender already wrote valid data, and we don't want to defeat their write.
        const result = next(action);
        this.currentRevision = action.revision;
        return result;
      }
    }

    const prevState = store.getState()[this.namespace];
    const result = next(action);
    const nextState = store.getState()[this.namespace];

    if (this.isStale) {
      console.error(`[LAYOUTMDW ${this.namespace}] Stale state. Not persisting or broadcasting.`, action);
      const payload = createErrorNotification(
        'Your session is out of sync and changes will not be saved. Please refresh the page.',
      );
      store.dispatch({ type: 'GENERIC_ERROR_NO_TARGET', payload });
      return
    }

    const d = + new Date();
    const changeset = buildChangeset(prevState, nextState, this.revisionConfig);
    console.info(`[LAYOUTMDW ${this.namespace}] buildChangeset took`, + new Date() - d, 'ms');

    if (changeset.length === 0) {
      return result;
    }

    const needsRevision = changeset.some(it => it.revision);
    const currRevision = this.currentRevision;
    const nextRevision = this.currentRevision + (needsRevision ? 1 : 0);

    this.currentRevision = nextRevision;

    this.asyncQueue.push(
      async () => {
        const m = + new Date();
        const dbRequest = {
          revision: currRevision,
          changeset: _cloneDeep(changeset),
        };
        const bcAction = needsRevision ? _cloneDeep({ ...action }) : null;
        const storageState = applyLocalstorageWriteModifications(nextState, this.revisionConfig, this.ignorePaths);

        console.log(`[LAYOUTMDW ${this.namespace}] queue.push() copy time:`, + new Date() - m, 'ms');

        const response = await this.persistChangeset(this.schemaVersion, dbRequest);
        return { responseRevision: response.revision, bcAction, needsRevision, storageState };
      },
      {
        onError: ({ error }) => {
          console.log(`[LAYOUTMDW ${this.namespace}] AsyncQueue Error:`, error, error?.response?.data);

          const data = error?.response?.data || {};
          let payload = null;

          if (!data.dbErr) {
            // Generic error, not from our own stuff
            payload = createErrorNotification(
              'Failed to save changes. You may have lost internet connection. Please try refreshing the page.',
            )
          } else if (data.code === 'RevisionCheckFailed') {
            payload = createErrorNotification(data.message);
            this.isStale = true;
          } else {
            payload = createErrorNotification(data.message);
          }
          store.dispatch({ type: 'GENERIC_ERROR_NO_TARGET', payload });
        },
        onSuccess: ({ result }) => {
          const { responseRevision, bcAction, needsRevision, storageState } = result;
          if (needsRevision) {
            this.sendBroadcast(bcAction, responseRevision);
          }
          writeLocalStorage(this._getStorageKey(), responseRevision, storageState);
        }

      }
    )
  }
}

const debounceNotificationKey = (key, seconds = 10) => {
  return `${key}-${Math.floor(Date.now() / (seconds * 1000))}`;
}


/**
 * @param {string} storageKey
 * @returns {StoredValue|undefined}
 **/
function readLocalStorage(storageKey) {
  try {
    const storedValue = localStorage.getItem(storageKey);
    if (!storedValue) {
      return undefined;
    }
    const parsedValue = JSON.parse(storedValue);

    if (!parsedValue.data || !parsedValue.revision) {
      return undefined;
    }
    return parsedValue;
  } catch (err) {
    console.err('[HIST_INTER] Error reading local storage', err);
  }
  return undefined;
}


/**
 * @param {string} storageKey
 * @param {number} revision
 * @param {object} data
 * @throws {Error} if data cannot be stringified, or storage fails
 **/
function writeLocalStorage(storageKey, revision, data) {
  requestIdleCallback(() => {
    const valueToStore = { data, revision, writtenAt: Date.now() };
    const stringified = JSON.stringify(valueToStore);
    localStorage.setItem(storageKey, stringified);
  }, { timeout: 1500 });
}


/**
 * @param {string} storageKey
 **/
function clearLocalStorage(storageKey) {
  try {
    localStorage.removeItem(storageKey);
  } catch (err) {
    console.err('[HIST_INTER] Error clearing local storage', err);
  }
}

