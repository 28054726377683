import { formatMarketTime, getCurrentTradingDay, getMarketSession, isDateDuringLiveSessions, parseUnixSeconds, toMarketTime } from 'src/utils/datetime/date-fns.tz';
import edgeDataApi from 'src/apis/edgeDataApi';
import * as Sentry from '@sentry/react';
import { MARKET_SESSION_KEYS } from 'src/utils/datetime/definitions/marketHours';
import { addDays, differenceInSeconds, getUnixTime, subDays, subMinutes, subYears } from 'date-fns';


/**
 * Loads a large amount of bars in-memory for 1 ticker and resolution. and selectively doles them out when TradingView asks to make a request.
 * If the buffer runs out of bars, it will re-fetch another large batch.
 *
 * Originally deleted bars once dolled out, but now keeps them around as we need in-memory caching to cover all the situations
 * where TradingView refetches. (Mosaic move, Layout load, Template load, Drawing persist. TV was not built for our use case.)
 *
 * If looking at minute resolution data, it will make its first request to quotemedia, since QM has accurate recent volume data.
 * Every request otherwise will be from polygon. Polygon becomes accurate after 15 mins.
 *
 * If a ticker has a previous symbol change, the buffer will only load bars from toDate to changeDate.
 * After change date, the bars will return, the buffer will set its ticker to the old ticker, and the DataFeed will ask for more
 * bars, since the previous request didn't satisfy its countBack number.
 */
export default class BarBuffer {

  buffer = []; // newest to oldest order
  resolution = null;
  serverHasMoreBars = true;
  /**
   * @member {integer}
   * Unix seconds
   */
  previouslyRequestedToDate = null;
  /**
   * @member {Date}
   * Localized to Market
   * */
  lastTopOffDateObj = null;
  loadBarsPromise = Promise.resolve(null);
  topOffBarsPromise = Promise.resolve(null);
  polygonScaleMap = {
    m: 'minute',
    D: 'day',
    W: 'week',
    M: 'month'
  };


  /**
   * @constructor
   * @param {string} ticker
   * @param {string} resolution
   * @param {integer} [limit=200]
   * @param {boolean} isHistorical
   */
  constructor(ticker, resolution, limit = 2000, isHistorical) {
    this.ticker = ticker;
    this.resolution = resolution;
    this.limit = Math.min(Math.max(limit, 100), 50_000);
    this.isHistorical = isHistorical;
    /**
     * @member {UnixMilliseconds}
     * Unix timestamp milliseconds
     */
    this.oldestBarLoadedTime = null;
    /**
     * @member {UnixMilliseconds}
     * Unix timestamp milliseconds
     */
    this.forceNextServerToDate = null;
  }

  /**
   *
   * @param {UnixMilliseconds} cursorBarTime
   * @return {integer} - The index of that bar in the cache
   */
  getCursorBarIndex(cursorBarTime) {
    return this.buffer.findIndex(b => b.time === cursorBarTime);
  }


  barsLeftInBuffer(startBarIndex) {
    // console.log(`[barBuffer] bars left in buffer ${this.buffer.length - startBarIndex} ${this.buffer.length} ${startBarIndex}`);
    return this.buffer.length - startBarIndex;
  }

  /**
   * Do we have enough bars in the cache?
   * @param {integer} numberBarsRequested
   * @param {UnixMilliseconds} cursorBarTime
   * @return {false|boolean}
   */
  shouldLoadHistoricalBars(numberBarsRequested, cursorBarTime) {
    const startBarIndex = this.getCursorBarIndex(cursorBarTime) + 1;
    return (this.barsLeftInBuffer(startBarIndex) < numberBarsRequested && this.serverHasMoreBars);
  }

  /**
   * When a user navigates away from a chart, we stop recieving streaming data. But the cache still persists.
   * If the user flicks back to that chart, we will be missing that period of data.
   * This function attempts to detect this misisng data.
   * @param cursorBarTime
   * @return {boolean}
   */
  shouldTopOffBuffer(cursorBarTime) {
    // console.log('[barBuffer] TIME', cursorBarTime, 'LAST', this.lastTopOffDateObj);
    // buffer hasn't started loading yet (aka, there is nothing cached. The loadHistorical endpoint will get everything we need)
    if (this.buffer.length === 0) {
      // console.log('[barBuffer] no top, length = 0');
      return false;
    }

    // chart isn't realtime
    if (this.isHistorical) {
      // console.log('[barBuffer] no top, isHistorical');
      return false;
    }

    // chart isn't asking for most recent data (aka, this isn't the initial load)
    if (cursorBarTime !== -1) {
      // console.log('[barBuffer] no top, cursor !== -1');
      return false;
    }

    const [range, scale] = this.parseResolution(this.resolution); // eslint-disable-line no-unused-vars

    let liveSessions;
    if (scale === 'minute') {
      // all sessions valid in intraday
      liveSessions = [MARKET_SESSION_KEYS.PREMARKET, MARKET_SESSION_KEYS.REGULAR_MARKET, MARKET_SESSION_KEYS.AFTER_HOURS];
    } else {
      // daily. Premarket shouldn't render. Regular renders normal. After should only update volume. the endpoint we use should be accurate, only volume will change.
      liveSessions = [MARKET_SESSION_KEYS.REGULAR_MARKET, MARKET_SESSION_KEYS.AFTER_HOURS];
    }

    // console.log('[barBuffer]', liveSessions, range, scale);

    if (!isDateDuringLiveSessions(toMarketTime(this.lastTopOffDateObj), liveSessions)) {
      // console.log('[barBuffer] no top not live sessions. session', getMarketSession(toMarketTime(this.lastTopOffDateObj)));
      return false;
    }

    // Debounce the top off. The chart does its wacky loading thing, it could initiate the top off multiple times.
    const now = new Date();
    const secondsElapsed = differenceInSeconds(now, this.lastTopOffDateObj);
    const shouldTop = secondsElapsed > 5;

    // console.log('[barBuffer] shouldTop', shouldTop, 'secondsElapsed', secondsElapsed, 'lastTopOffDateObj', this.lastTopOffDateObj, 'now', now)

    return shouldTop;
  }

  /**
   * @async
   * @param {PeriodParams} periodParams
   * @param {UnixMilliseconds} cursorBarTime - The earliest bar loaded in the chart
   * @return {Object[]|boolean} - The resulting bars
   */
  async retrieveFromBuffer(periodParams, cursorBarTime) {
    const { from, to, countBack: numberBarsRequested, firstDataRequest } = periodParams;
    // console.log('[barBuffer] START', cursorBarTime);

    await this.loadBarsPromise;
    await this.topOffBarsPromise;

    // If time has elapsed since the buffer was first created, and a new chart is asking for most recent data, we might need new bars for the start of the buffer.
    // Intraday: closed the chart at 10:00, repopened at 10:10am. Cache is missing 10 bars, we need to fetch.
    // Daily/Weekly/Monthly: closed the chart at 10:00, reopened at 10:10am. The chart isn't missing bars, but it may be missing H/L values.
    // Fetch quote from our db to be sure.
    if (this.shouldTopOffBuffer(cursorBarTime)) {
      const [range, scale] = this.parseResolution(this.resolution); // eslint-disable-line no-unused-vars

      if (scale === 'minute') {
        // console.log('[barBuffer] TOPPING INTRADAY (update or add as many bars as needed)');
        this.topOffBarsPromise = this.topOffIntradayBars();
      } else {
        // console.log('[barBuffer] TOPPING D/W/M (update 1 bar)');
        this.topOffBarsPromise = this.topOfDailyQuote();
      }

      await this.topOffBarsPromise;
    }

    // Load historical bars if the buffer is too small to satisfy the request.
    if (this.shouldLoadHistoricalBars(numberBarsRequested, cursorBarTime)) {
      // console.log('[barBuffer] LOADING');
      const toTime = this.adjustToDate(from, to, firstDataRequest);
      this.loadBarsPromise = this.loadHistoricalBarsIntoBuffer(toTime, firstDataRequest);
      await this.loadBarsPromise;
    }


    const cursorBarIndex = this.getCursorBarIndex(cursorBarTime);
    const startBarIndex = cursorBarIndex + 1;
    // console.log('[barBuffer] CURSOR IDX', cursorBarIndex);


    if (!this.barsLeftInBuffer(startBarIndex)) {
      return false;
    }

    // return bars starting at bufferIdx to numberBarsRequested.
    const requestedEndIndex = startBarIndex + numberBarsRequested;
    // console.log(`[barBuffer] SLICE(${startBarIndex}, ${requestedEndIndex}), buffer length: ${this.buffer.length}`);

    let barChunk = this.buffer.slice(startBarIndex, requestedEndIndex);

    // Cut the data off if we see massive gaps in trading days
    barChunk = this.checkForTradingDayGaps(barChunk, 90);

    // copy before returning
    return [...barChunk.reverse().map(bar => ({ ...bar }))];
  }

  /**
   * Get minute bars that haven't been added to the cache, while the user was away from the chart.
   * @async
   * @return {boolean} success
   */
  async topOffIntradayBars() {
    const adjustedLimit = this.calculateAdjustedLimit(this.resolution, this.limit);
    const [range, scale] = this.parseResolution(this.resolution);

    const nowObj = new Date();

    // Add a 2-minute buffer from the last time we topped off.
    // So, last top off = 10:00am. Now = 10:10am. We will request bars from 9:58am to 10:10am.
    const to = getUnixTime(nowObj);
    const from = getUnixTime(subMinutes(this.lastTopOffDateObj, 2))

    this.lastTopOffDateObj = nowObj;

    let newBars = [];
    try {
      const url = `/time-series-full/${this.ticker}/range/${range}/${scale}/${from}/${to}`;
      const qs = `sort=desc&limit=${adjustedLimit}&firstDataRequest=${true}`;

      const response = await edgeDataApi.get(`${url}?${qs}`);

      const { bars } = response.data;
      newBars = bars;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      return false;
    }

    if (!newBars) {
      return false;
    }

    this.buffer = mergeBars(this.buffer, newBars);
    this.oldestBarLoadedTime = this.buffer[this.buffer.length - 1].time;
  }

  /**
   * Top off most recent bar only. Don't add new bars.
   * @async
   * @returns {boolean} - success
   */
  async topOfDailyQuote() {
    this.lastTopOffDateObj = new Date();

    let quote;
    try {
      const { data } = await edgeDataApi.get(`time-series-quote/${this.ticker}`);
      quote = data;
    } catch (err) {
      console.error(err);
      return false;
    }

    if (quote && quote?.volume) {
      this.buffer[0] = {
        ...this.buffer[0],
        ...quote
      };
    }

    return true;
  }

  /**
   *
   * @param to
   * @param firstDataRequest
   * @return {Promise<boolean>}
   */
  async loadHistoricalBarsIntoBuffer(to, firstDataRequest) {
    // ignore if chart is asking for same data twice
    if (this.previouslyRequestedToDate === to) {
      return false;
    }
    this.previouslyRequestedToDate = to;

    if (!this.lastTopOffDateObj) {
      this.lastTopOffDateObj = new Date();
    }

    // calculate the limit query param, based on resolution.
    const adjustedLimit = this.calculateAdjustedLimit(this.resolution, this.limit);
    const [range, scale] = this.parseResolution(this.resolution);

    // Set a large date range so the buffer can load more bars than requested. Limit param will control request size.
    const bufferedFromDate = getUnixTime(subYears(parseUnixSeconds(to), 20));

    let newBars = [];
    try {
      const url = `/time-series-full/${this.ticker}/range/${range}/${scale}/${bufferedFromDate}/${to}`;
      const qs = `sort=desc&limit=${adjustedLimit}&firstDataRequest=${firstDataRequest}`;

      const response = await edgeDataApi.get(`${url}?${qs}`);

      const { bars, forceNextToDate } = response.data;
      newBars = bars;

      if (forceNextToDate) {
        this.forceNextServerToDate = forceNextToDate;
      }

    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      this.serverHasMoreBars = false;
    }

    // Add bars to buffer
    if (!newBars || !newBars.length) {
      this.serverHasMoreBars = false;
      return;
    }

    this.buffer = mergeBars(this.buffer, newBars);
    this.oldestBarLoadedTime = this.buffer[this.buffer.length - 1].time;
  }

  /**
   * We may need to change the date the Chart is asking for.
   * @param {UnixSeconds} from
   * @param {UnixSeconds} to
   * @param {boolean} firstDataRequest
   * @return {integer} - Unix timestmap seconds
   */
  adjustToDate(from, to, firstDataRequest) {
    let toTime = to;
    if (this.forceNextServerToDate) {
      // The server explicitely told us to set the date
      toTime = this.forceNextServerToDate;
      this.forceNextServerToDate = null;
    } else if (firstDataRequest) {
      // if first request, use the given to date
      toTime = to;

      const nowDate = new Date()
      const nowDateMarket = toMarketTime(nowDate);
      const toDateMarket = toMarketTime(parseUnixSeconds(to));
      const [range, scale] = this.parseResolution(this.resolution); // eslint-disable-line no-unused-vars

      // if before noon, the chart will not ask for today's bar. Adjust it. TODO: Why? Who knows. I've turned every single knob and nothing changes.
      if (scale !== 'minute' &&
        nowDateMarket.getHours() < 12 &&
        formatMarketTime(toDateMarket, 'yyyy-MM-dd') === formatMarketTime(subDays(nowDateMarket, 1), 'yyyy-MM-dd')
      ) {
        toTime = getUnixTime(addDays(nowDate, 1));
      }
    } else if (this.oldestBarLoadedTime) {
      // if not first request, load from the oldest buffer;
      toTime = parseInt(this.oldestBarLoadedTime / 1000) - 1;
    }
    return toTime;
  }

  /**
   * We need to limit the data we get back from polygon, but the limits are different for each resolution. Calculate the limits here.
   * If you set limit to 100 on daily resolution, 100 bars will be returned. However, if you set limit to 100 for weekly, only 20 bars are returned.
   * This is because polygon counts the base unit as the limit, not the multiple (week = 5 x 1 day), 100 days = 20 weeks
   * @param {string} resolution
   * @param {integer} limit
   * @returns {integer} - new limit
   */
  calculateAdjustedLimit(resolution, limit) {
    const MAX = 50_000;
    const [range, scale] = this.parseResolution(resolution);
    let adjusted = limit;
    if (scale === 'minute' || scale === 'day') {
      adjusted = range * limit;
    }
    if (scale === 'week') {
      adjusted = range * (limit * 5);
    }
    if (scale === 'month') {
      adjusted = range * (limit * 30);
    }
    return Math.min(MAX, adjusted);
  }


  /**
   * Convert Trading Views scale/range string into parts that polygon accepts.
   * @param {string} resolution
   * @returns {[string, string]} [resolution, scale]
   */
  parseResolution(resolution) {
    const scaleLetter = this.parseScaleLetter(resolution);
    return [parseInt(resolution), this.polygonScaleMap[scaleLetter]];
  }


  /**
   * @param {string} resolution
   * @return {string}
   */
  parseScaleLetter(resolution) {
    if (!isNaN(resolution)) {
      return 'm';
    } else {
      return resolution[resolution.length - 1];
    }
  }


  /**
   * If we see a large gap in time between two bars, we can assume the previous set belonged to a different company.
   * We don't want to display this old company's data, so cut off the buffer and signify that this company has no more data.
   * @param {Object[]} bars
   * @param {integer} days
   * @returns {Object[]}
   */
  checkForTradingDayGaps(bars, days) {
    const UNIX_GAP = 86400 * days * 1000;
    for (let i = 1; i < bars.length; i++) {
      const prevBar = bars[i - 1];
      const bar = bars[i];
      if (prevBar.time - bar.time >= UNIX_GAP) {
        this.serverHasMoreBars = false;
        return bars.slice(0, i);
      }
    }
    return bars;
  }

}


/**
 * Replaces old bars with new if timestamp is the same, else adds new bar. Sorts.
 * @param {Object[]} oldBars
 * @param {Object[]} newBars
 * @returns {Object[]} - mergedBars
 */
const mergeBars = (oldBars, newBars) => {
  const mergedBars = oldBars.slice();

  for (const newBar of newBars) {
    const oldBarIndex = mergedBars.findIndex(bar => bar.time === newBar.time);

    if (oldBarIndex !== -1) {
      mergedBars[oldBarIndex] = newBar;
    } else {
      mergedBars.push(newBar);
    }
  }

  // Sort the merged array by the timestamp
  mergedBars.sort((a, b) => b.time - a.time);

  return mergedBars;
};
