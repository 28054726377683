import { PREDEF_PREFIX } from '../profileSettings/profileSettingsConfig';
import {
  predefinedHistoryColumnProfiles,
  predefinedHistoryFilterProfiles,
  predefinedHistoryAggregateProfiles,
  predefinedHistoryTimeseriesProfiles,
} from './historyPageIntermediateProfileBases';


export const TIME_SERIES_PERIODS = {
  DAY: 'D',
  WEEK: 'W',
  MONTH: 'M',
}


export const HARD_CODED_COMPONENTS = {
  filters: 'filters',
  chart: 'chart',
  timeseriesChart: 'timeseriesChart',
  records: 'records',
  marketStats: 'marketStats',
  exports: 'exports',
  news: 'news'
}


/*
 * The point of this redux slice is to keep the structure similar to Layouts,
 * but not actually integrated/sharing variables.
 *
 * This is going to make migrating to layouts easier in the future, without forcing
 * us to solve the hard parts yet.
 *
 * Eventually all layouts will inherit from a single schema.
 **/

export const PROFILE_TYPES = {
  FILTER: 'FILTER',
  COLUMN: 'COLUMN',
  AGGREGATE: 'AGGREGATE'
}


/**
 * Holds information about our various User profiles, and their default values
 *
 * @typedef {Object} ProfileConfigItem
 * @property {string} listKey - the name of the key in Profile state where the list of profiles is stored
 * @property {string} idKey   - the name of the key in Component state where the individual ID is stored
 * @property {Object[]} predefinedProfiles
 * @property {boolean} [nonLayoutState] - Don't process these profiles in layoutActions. Don't save to dynamo, don't add predefineds, don't strip predefineds.
 * @property {string} defaultProfileId
 **/


export const PROFILE_CONFIG = {
  HISTORY_FILTERS: {
    listKey: 'historyFilterProfiles',
    idKey: 'historyFilterProfileId',
    type: PROFILE_TYPES.FILTER,
    predefinedProfiles: predefinedHistoryFilterProfiles,
    defaultProfileId: `${PREDEF_PREFIX}recent-gappers`
  },
  HISTORY_COLUMNS: {
    listKey: 'historyColumnProfiles',
    idKey: 'historyColumnProfileId',
    type: PROFILE_TYPES.COLUMN,
    predefinedProfiles: predefinedHistoryColumnProfiles,
    defaultProfileId: `${PREDEF_PREFIX}large-cap`
  },
  HISTORY_AGGREGATES: {
    listKey: 'historyAggregateProfiles',
    idKey: 'historyAggregateProfileId',
    type: PROFILE_TYPES.AGGREGATE,
    predefinedProfiles: predefinedHistoryAggregateProfiles,
    defaultProfileId: predefinedHistoryAggregateProfiles[0].id
  },
  HISTORY_TIMESERIES: {
    listKey: 'historyTimeseriesProfiles',
    idKey: 'historyTimeseriesProfileId',
    type: PROFILE_TYPES.AGGREGATE,
    // SUBTYPE: TIMESERIES? or new Type entirely?
    predefinedProfiles: predefinedHistoryTimeseriesProfiles,
    defaultProfileId: predefinedHistoryTimeseriesProfiles[0].id
  },
  // NEWS_COLUMNS: {
  //   listKey: 'newsColumnProfiles',
  //   idKey: 'newsColumnProfileId',
  //   type: PROFILE_TYPES.COLUMN,
  //   predefinedProfiles: defaultLayoutNewsColumnProfiles,
  //   defaultProfileId: defaultLayoutNewsColumnProfiles[0].id
  // },
};

