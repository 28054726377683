import produce from 'immer';
import _isEqual from 'lodash/isEqual';
import shortUuid from 'short-uuid';
import { createSelector } from 'reselect';
import { EXPRESSION_FORMATS, EXPRESSION_SYMBOLS, INPUTS, ALLOW_NULL } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import { COLUMN_TYPES } from 'src/app/slicedForm/shared/schema/schemaBuilder';

/**
 * We're going to try denormalizing. Every expression will have a 'context' key,
 * and consumers will filter by the given context. Like "realtime" or "historical"
 *
 * activeProfileId is meaningless, as its fully dependant on the Form being used.
 *
 * Theres some real tricky stuff here. If someone deletes an expression, we need to
 * check ALL potential references, in all other reducers. Tough.
 *
 * This means that we will never be dispatching a simple action to only this reducer.
 * All expression updates have to be sent through multiple places.
 *
 * NOTE:
 * All forms are now going to be SlicedForms. Potentially, the same exact state solution
 * can be used everywhere. We can have a single recursive parser for this. 
 * If we have other types of state using expressions, this becomes more annoying.
 *
 * I'm leaning towards IDEA 2.
 *  - FormProvider can check before initializing
 *  - ProfileToQuery will recieve expressions, and should know how to remove the offending node.
 *
 * IDEA 1:
 *
 * When we pick an expression inside a form context, we copy the key but we also copy the 
 * entire expression to the form itslef.
 *
 * When we load the form, we first check for the key. If its not deleted, we use that. If it is
 * deleted, we use the copy. If we're using the copy, we show a warning to the user. If they open
 * the expression, we show it under "UNSAVED EXPRESSIONS", and they can choose to resave.
 *
 *   PROBLEM: The copy isn't going to recieve updates from the original. How can we propagate that?
 *            - I guess during the initial check we can do it. But that causes render loops unless
 *              we're smart.
 *
 * IDEA 2:
 *
 * We make each context compliant. If a form loads, checks the expression, and its deleted, we simply
 * delete it then. We could do it in the SlicedForms init method. 
 *
 *  PROBLEM: The logic to handle this is going to be different for each form. Kind of annoying, easy
 *           to make mistakes
 *  PROBLEM: Potentially a lot of UI work. 
 *  PROBLEM: We can have deleted expressions while the request for the data is being sent. We need to
 *           also check before each request. 
 *              - But hang on, we already need to do that to get the actual data.
 *
 * IDEA 3:
 *
 * We just enforce that all dispatch() must run through all reducers, and define the delete method
 * for each one. For some reason this seems like a nightmare
 *
 *  NEVERMIND: THIS IS WRONG BELOW. useReducer recieves initialState only! good!
 *  PROBLEM: We have a form. 5 unsaved fields so far. 
 *    - We open an expression form. Delete one expression.
 *    - Expression reducer is hit. But, Profile reducer is also hit.
 *    - The FormProvider recieves the new update from reducer.
 *    - That reducer doesn't have the 5 unsaved fields.
 *    - All users work has just been deleted.
 **/


export const EXPR_CTX = {
  SCANNER: 'scanner',
  HISTORICAL_COMP: 'historical_comp',
  HISTORY_PAGE: 'history_page',
}


export const EXPR_VERSION = 'v2';

const defaultProperties = {
  label: 'New Expression',
  expression: '',
  format: EXPRESSION_FORMATS.COMPACT,
  symbol: EXPRESSION_SYMBOLS.CURRENCY,
  args: {}
}


export const staticExpressionGridProperties = {
  columnType: COLUMN_TYPES.expression.name,
  comparesTo: COLUMN_TYPES.expression.comparesTo,
  // This should be on filters, not columns. Hmm..
  allowNull: ALLOW_NULL.USER_CONTROLLED,
  allowNullDefault: false,
  input: INPUTS.COMPARE,
  timeSlice: true
}


export const emptyExpression = {
  ...defaultProperties,
  ...staticExpressionGridProperties,
}

export const EXPR_PREFIX = 'expr_';

/**
 * Generate a unique ID for an expression.
 * Prefixed with expr_, as in many places on the Forms,
 * its far easier to check a prefix than to push a flag 
 * all the way down through state.
 *
 * Like: 'expr_31fb686dd4bb'
 **/
export const generateExpressionName = () => {
  return `${EXPR_PREFIX}${shortUuid.generate()}`
}


const initialState = {
  expressions: [
    {
      ...defaultProperties,
      name: 'expr_scanner_0',
      label: 'High Chg',
      expression: 'GREATEST( [[A]], [[B]] ) - [[C]]',
      contextKey: EXPR_CTX.SCANNER,
      format: EXPRESSION_FORMATS.FIXED,
      symbol: EXPRESSION_SYMBOLS.CURRENCY,
      args: {
        'A': { column: 'h' },
        'B': { column: 'pm_h' },
        'C': { column: 'day_m1_close' }
      },
    },
    // {
    //   ...defaultProperties,
    //   name: 'expr_scanner_1',
    //   label: 'Todays Range %',
    //   expression: '( [[A]] - [[B]] ) / [[B]] * 100',
    //   contextKey: EXPR_CTX.SCANNER,
    //   format: EXPRESSION_FORMATS.FIXED,
    //   symbol: EXPRESSION_SYMBOLS.PERCENTAGE,
    //   args: {
    //     'A': { column: 'h' },
    //     'B': { column: 'l' },
    //   }
    // },

    // {
    //   ...defaultProperties,
    //   name: 'expr_history_comp_0',
    //   label: 'O+10*Chg/2',
    //   expression: '[[A]] + 10 * [[B]] / 2',
    //   contextKey: EXPR_CTX.HISTORICAL_COMP,
    //   format: EXPRESSION_FORMATS.FIXED,
    //   symbol: EXPRESSION_SYMBOLS.CURRENCY,
    //   args: {
    //     A: { column: 'day0_open' },
    //     B: { column: 'day0_chg' },
    //   }
    // }
  ],
  isFetching: false, // CHANGE LATER
}

/** Persist a contextKey's expressions */
export const UPDATE_EXPRESSIONS = '@global-expression/UPDATE_EXPRESSIONS';
/** Must call after UPDATE_EXPRESSIONS */
export const WRITE_EXPRESSIONS_LOCAL_STORAGE = '@global-expression/WRITE_EXPRESSIONS_LOCAL_STORAGE';
/** Load from server **/
export const INITIALIZE_EXPRESSIONS = '@global-expression/INITIALIZE_EXPRESSIONS';
export const BEGIN_FETCH_EXPRESSIONS = '@global-expression/BEGIN_FETCH_EXPRESSIONS';
export const MARK_EXPRESSIONS_FETCHED = '@global-expression/MARK_EXPRESSIONS_FETCHED';


/**
 * Remove extra properties added to the expression for the Grid,
 * but not wanted inside redux. Apply before reducing.
 **/
export const cleanExpression = (expression, contextKey) => {
  const { unmap, invalid, ...rest } = expression;

  if (unmap || invalid) {
    console.warn(`Cleanup expression: unmap or invalid found in expression. Did you make a mistake? ${expression.expression}<${expression.args}>`);
  }
  // Remove some properties we don't want in redux/database
  const cleanedExpr = Object.entries(rest).reduce((acc, [key, val]) => {
    if (key in staticExpressionGridProperties) return acc;

    return { ...acc, [key]: val };
  }, {})

  return { ...cleanedExpr, contextKey };
}



/*
 * We are going with #2.
 * These will be normalized, select by filter context key
 **/
const globalExpressionReducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {

      case WRITE_EXPRESSIONS_LOCAL_STORAGE: {
        if (!action.writeLocalStorage || !(typeof action.writeLocalStorage === 'number')) {
          throw new Error('WRITE_EXPRESSIONS_LOCAL_STORAGE action.writeLocalStorage must be a Timestamp in order to write');
        }
        // No target. We're just writing the whole slice of state to local storage in middleware.
        //
        // Normally, you'd want to do this in the same action as UPDATE_EXPRESSIONS. You can still do that,
        // but for Toplist layouts, we MUST update localstorage after the DB returns 200. Otherwise we lose
        // sync with server.
        break;
      }

      case BEGIN_FETCH_EXPRESSIONS: {
        draft.isFetching = true;
        break;
      }

      case MARK_EXPRESSIONS_FETCHED: {
        draft.isFetching = false;
        break;
      }

      case INITIALIZE_EXPRESSIONS: {
        const { expressions } = action.payload;

        draft.expressions = expressions;
        draft.isFetching = false;

        break;
      }

      case UPDATE_EXPRESSIONS: {
        const { expressions, contextKey } = action.payload;

        // Delete if incomming array doesn't contain ID
        const toDelete = draft.expressions
          .filter(de => de.contextKey === contextKey)
          .filter(de => !expressions.some(ie => ie.name === de.name))
          .map(de => de.name);

        for (const dname of toDelete) {
          const idx = draft.expressions.findIndex(e => e.name === dname);
          if (idx !== -1) draft.expressions.splice(idx, 1);
        }

        // add or update otherwise
        expressions.forEach(ie => {
          if (!ie.contextKey || ie.unmap || ie.invalid || ie.input) {
            throw new Error(`Expression was not cleaned before reducing, ${ie}`)
          }
          const existingExpressionIdx = draft.expressions.findIndex(de => de.name === ie.name);

          if (existingExpressionIdx === -1) {
            draft.expressions.push(ie);
          } else {
            if (!_isEqual(draft.expressions[existingExpressionIdx], ie)) {
              draft.expressions[existingExpressionIdx] = ie;
            }
          }
        });

        if (action.writeLocalStorage) {
          if (!(typeof action.writeLocalStorage === 'number')) {
            throw new Error('UPDATE_EXPRESSIONS action.writeLocalStorage must be a Timestamp in order to write');
          }
        }

        break;
      }


      default: {
        break;
      }
    }

  })
}


/**
 * Select all expressions under a given contextKey.
 * Adds the static properties on when called.
 **/
export const makeSelectListExpressions = () => createSelector(
  [
    state => state.expressions.expressions,
    (_, ctx) => ctx
  ],
  (expressions, ctx) => expressions.filter(e => e.contextKey === ctx).map(e => ({ ...e, ...staticExpressionGridProperties }))
);



export default globalExpressionReducer;
