/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import AppLayout from 'src/layouts/AppLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import BillingGuard from 'src/components/BillingGuard';

/*
Main configuration for routes. This is not the complete list however:
Routes in other files:
  /billing, Used for initial billing process for users with new accounts, or deactivated/delinquent accounts
  /account, For general info and updating billing details for existing customers

Details on components:
- AppLayout includes all of the main sidebar pages
  - Authguard ensures authentication, and will redirect elsewhere for billing errors
- Other pages without layouts:
  - BillingGuard does AuthGuards job in the /billing context
*/

const routesConfig = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/password-reset',
    component: lazy(() => import('src/views/auth/PasswordResetView'))
  },
  {
    exact: true,
    path: '/email-verification',
    component: lazy(() => import('src/views/auth/EmailVerificationLandingPage'))
  },
  {
    exact: false,
    path: '/billing',
    guard: BillingGuard,
    component: lazy(() => import('src/views/billing'))
  },
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/top-lists/" />
  },
  {
    exact: true,
    path: '/account',
    component: () => <Redirect to="/account/general" />
  },
  {
    exact: false,
    path: '/account',
    guard: AuthGuard,
    layout: AppLayout,
    component: lazy(() => import('src/app/AccountPage'))
  },
  {
    exact: true,
    path: '/history',
    guard: AuthGuard,
    layout: AppLayout,
    component: lazy(() => import('src/app/HistoryPage'))
  },
  {
    exact: true,
    path: '/history-inter',
    guard: AuthGuard,
    layout: AppLayout,
    component: lazy(() => import('src/app/HistoryPageIntermediate/HistoryPageIntermediate'))
  },
  {
    exact: true,
    path: '/top-lists',
    guard: AuthGuard,
    layout: AppLayout,
    layoutProps: { hideTickerSearch: true },
    component: lazy(() => import('src/app/TopListsMosaic'))
  },
  {
    exact: true,
    path: '/overview/:tickerId?',
    component: () => <Redirect to="/top-lists/" />
  },
  {
    exact: true,
    path: '/overview',
    component: () => <Redirect to="/top-lists/" />
  },
  {
    exact: true,
    path: '/SEC/:tickerId?',
    guard: AuthGuard,
    layout: AppLayout,
    component: lazy(() => import('src/app/SECFilingsPage')),
    layoutProps: { hasTabsBar: true }
  },
  {
    exact: true,
    path: '/news/:tickerId?',
    component: () => <Redirect to="/top-lists/" />
  },
  {
    exact: true,
    path: '/news',
    component: () => <Redirect to="/top-lists/" />
  },
  {
    exact: true,
    path: '/financials/:tickerId?',
    guard: AuthGuard,
    layout: AppLayout,
    component: lazy(() => import('src/app/FinancialsPage')),
    layoutProps: { hasTabsBar: true }
  },
  {
    exact: true,
    path: '/insiders/:tickerId?',
    guard: AuthGuard,
    layout: AppLayout,
    component: lazy(() => import('src/app/insidersPage')),
    layoutProps: { hasTabsBar: true }
  },
  {
    exact: false,
    path: '',
    component: () => <Redirect to="/404" />
  }
];


const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const layoutProps = route.layoutProps;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout {...layoutProps}>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />
                  }
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);


function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
