import produce from 'immer';
import _merge from 'lodash/merge';
import _isObject from 'lodash/isObject';
import { } from 'src/redux/history/historyActions';
import { createSelector } from 'reselect';
import {
  TIME_SERIES_PERIODS,
  HARD_CODED_COMPONENTS as COMPS,
  PROFILE_CONFIG as PCFG
} from './historyPageIntermediateSchema';
import { DEFAULT_TEMPLATE_ID } from 'src/app/ChartingLibrary/constants';
import {
  STORE_KEY,
  UPDATE_PROFILE,
  ADD_COMPONENT,
  UPDATE_COMPONENT,
  MODIFY_PROF,
  COPY_PROF,
  DELETE_PROF,
  DELETE_COMPONENT,

  UPDATE_UI_COMPONENT_STATE,
  INITIALIZE_START,
  INITIALIZE_SUCCESS
} from './historyPageIntermediateActions.js';
import { shallowEqual } from 'react-redux';
import { INIT_SOURCE } from './layoutSyncMiddleware/constants.js';
import shortUuid from 'short-uuid';


const initialState = {
  components: {
    [COMPS.filters]: {
      [PCFG.HISTORY_FILTERS.idKey]: PCFG.HISTORY_FILTERS.defaultProfileId,
    },
    [COMPS.chart]: {
      selectedTemplateId: DEFAULT_TEMPLATE_ID,
      interval: '1D',
    },
    [COMPS.timeseriesChart]: {
      period: TIME_SERIES_PERIODS.W,
      [PCFG.HISTORY_TIMESERIES.idKey]: PCFG.HISTORY_TIMESERIES.defaultProfileId,
    },
    [COMPS.records]: {
      order: 'desc',
      orderby: 'day0_date',
      [PCFG.HISTORY_COLUMNS.idKey]: PCFG.HISTORY_COLUMNS.defaultProfileId,
    },
    [COMPS.marketStats]: {
      [PCFG.HISTORY_AGGREGATES.idKey]: PCFG.HISTORY_AGGREGATES.defaultProfileId,
    },
  },
  profiles: {
    [PCFG.HISTORY_FILTERS.listKey]: PCFG.HISTORY_FILTERS.predefinedProfiles.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
    [PCFG.HISTORY_COLUMNS.listKey]: PCFG.HISTORY_COLUMNS.predefinedProfiles.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
    [PCFG.HISTORY_TIMESERIES.listKey]: PCFG.HISTORY_TIMESERIES.predefinedProfiles.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
    [PCFG.HISTORY_AGGREGATES.listKey]: PCFG.HISTORY_AGGREGATES.predefinedProfiles.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
  },
  orderings: {
    profiles: {
      [PCFG.HISTORY_FILTERS.listKey]: PCFG.HISTORY_FILTERS.predefinedProfiles.map(p => p.id),
      [PCFG.HISTORY_COLUMNS.listKey]: PCFG.HISTORY_COLUMNS.predefinedProfiles.map(p => p.id),
      [PCFG.HISTORY_TIMESERIES.listKey]: PCFG.HISTORY_TIMESERIES.predefinedProfiles.map(p => p.id),
      [PCFG.HISTORY_AGGREGATES.listKey]: PCFG.HISTORY_AGGREGATES.predefinedProfiles.map(p => p.id),
    }
  },
  ui: {
    components: {
      [COMPS.chart]: {
        fromDate: '',
        ticker: null,
      }
    }
  },
  isFetching: {
    initial: true, // all profile stuff
  },
}


export const getRemovedProfileIds = (nextProfiles, oldProfiles) => {
  const newProfileIds = Object.keys(nextProfiles);
  const oldProfileIds = Object.keys(oldProfiles);
  return oldProfileIds.filter(x => !newProfileIds.includes(x));
}



export default function historyPageIntermediateReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_UI_COMPONENT_STATE: {
        const { componentId, data = {} } = action.payload;

        if (!(componentId in draft.ui.components)) {
          break;
        }

        if (!_isObject(data)) {
          break;
        }

        _merge(draft.ui.components[componentId], data)

        break;
      }

      case UPDATE_COMPONENT: {
        const { componentId, data = {} } = action.payload;

        if (!(componentId in draft.components)) {
          break;
        }

        if (!_isObject(data)) {
          break;
        }

        _merge(draft.components[componentId], data)

        break;
      }

      case ADD_COMPONENT: {
        const { componentId, data } = action.payload;
        draft.components[componentId] = data;
        break;
      }

      case DELETE_COMPONENT: {
        const { componentId } = action.payload;
        delete draft.components[componentId];
        break;
      }

      case UPDATE_PROFILE: {
        // Ideally we'd rather not update profiles if not necissary
        // We'd have to deepEquals, or we'd have to have a flag inside the Forms.
        // TODO: Maybe look into deepEquals.
        const { profileSettings, componentId, profileConfig } = action.payload;
        const { idKey, listKey, defaultProfileId } = profileConfig;
        const { activeProfile: activeProfileId, profileList } = profileSettings;

        // Convert returned array into map+order
        const ordering = [];
        const profiles = profileList.reduce((acc, curr) => {
          ordering.push(curr.id);
          return { ...acc, [curr.id]: curr };
        }, {});

        if (componentId in draft.components) {
          draft[componentId][idKey] = activeProfileId;
        }

        const removedProfileIds = getRemovedProfileIds(profiles, draft[listKey]);

        Object.entries(state.components).forEach(([componentId, componentData]) => {
          if (idKey in componentData && removedProfileIds.includes(componentData[idKey])) {
            draft.components[componentId][idKey] = defaultProfileId;
          }
        });

        draft.profiles[listKey] = profiles;
        draft.orderings.profiles[listKey] = ordering;

        break;
      }

      case DELETE_PROF: {
        const { type, profileId } = action.payload;
        console.log('DELETE_PROF', type, profileId)
        const cfg = Object.values(PCFG).find(c => c.listKey === type);

        if (cfg.predefinedProfiles.map(p => p.id).includes(profileId)) {
          console.log('CANT DELETE PREDEFINED')
          break;
        }

        delete draft.profiles[cfg.listKey][profileId];
        draft.orderings.profiles[cfg.listKey] = draft.orderings.profiles[cfg.listKey].filter(id => id !== profileId);

        break;
      }

      case MODIFY_PROF: {
        const { type, key } = action.payload;
        const cfg = Object.values(PCFG).find(c => c.idKey === type);

        const profile = draft.profiles[cfg.listKey]?.[key];
        if (!profile) {
          console.log('NO PROF TO MODIF')
          break;
        }

        profile.name = 'MODIFIED ' + shortUuid.generate();
        profile.value = Math.random();

        break;
      }

      case COPY_PROF: {
        const { type, key: oldId, id, name } = action.payload;
        const cfg = Object.values(PCFG).find(c => c.idKey === type);

        const profile = draft.profiles[cfg.listKey]?.[oldId];
        if (!profile) {
          console.log('NO PROF TO COPY')
          break;
        }

        const { predefined, ...rest } = profile;

        draft.profiles[cfg.listKey][id] = {
          ...rest,
          name,
          id,
          value: Math.random()
        }

        draft.orderings.profiles[cfg.listKey].push(id);

        break;
      }

      case INITIALIZE_START: {
        draft.isFetching.initial = true;
        break;
      }

      case INITIALIZE_SUCCESS: {
        const { source, state: incomingSate, revision } = action.payload;

        if (source === INIT_SOURCE.initialState) {
          draft.isFetching.initial = false;
          break;
        }

        return {
          ...draft,
          ...incomingSate,
          isFetching: {
            ...draft.isFetching,
            initial: false
          }
        }
      }

      default: {
        return state;
      }
    }
  })
}

export const selectProfileMap = (state, listKey) => {
  return state[STORE_KEY].profiles[listKey];
};

export const selectProfileOrder = (state, listKey) => {
  return state[STORE_KEY].orderings.profiles[listKey];
}

export const selectChartUiSettings = (state) => {
  return state[STORE_KEY].ui[COMPS.chart];
}

export const selectActiveGlobalFilterProfileId = (state) => {
  return state[STORE_KEY].components[COMPS.filters][PCFG.HISTORY_FILTERS.listKey];
}

export const selectGlobalFilterProfileList = (state) => {
  return state[STORE_KEY].profiles[PCFG.HISTORY_FILTERS.listKey];
}

export const selectActiveGlobalFilterProfile = createSelector(
  [selectActiveGlobalFilterProfileId, selectGlobalFilterProfileList],
  (id, profiles) => profiles.find(p => p.id === id)
)


/** 
 * Applys ordering array to profile map, returns array *
 * @example 
 * const profileList = useParameterizedSelector(selectProfileList, PROFILE_TYPES.HISTORY_FILTERS.listKey);
 */
export const makeSelectProfileList = () => createSelector(
  [selectProfileMap, selectProfileOrder],
  (profiles, order) => order.map(id => profiles[id])
)


/**
 * @example
 * const componentData = useParameterizedSelector(makeSelectComponent, 'records')
 **/
export const makeSelectComponent = () => createSelector(
  [
    state => state[STORE_KEY].components,
    (_, componentId) => componentId,
  ],
  (components, componentId) => {
    const data = components?.[componentId];
    if (!data) throw new Error(`HistoryIntermediate component ${componentId} not found`);
    return data;
  }
)

