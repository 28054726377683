import React, { useEffect, useContext, useState, useCallback } from 'react';
import clsx from 'clsx';
import { CHART_DATE_INITIALIZATION_TYPES } from 'src/redux/layout/topListLayoutSchema';
import { CLIENT_IDS, DEFAULT_LAYOUT_ID, DEFAULT_TEMPLATE_ID } from 'src/app/ChartingLibrary/constants';
import LayoutContext from 'src/app/TopListsMosaic/layout/LayoutContext';
import { useSelector, useDispatch } from 'react-redux';
import { selectComponent } from 'src/redux/layout/topListLayoutSelectors';
import { updateComponent } from 'src/redux/layout/topListLayoutActions';
import useToplistLinkedValues from 'src/hooks/useToplistLinkedValues';
import MosaicPanelHeader from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/MosaicPanelHeader';
import IntercomArticleButton, { INTERCOM_SLUGS } from 'src/app/components/intercom/IntercomArticleButton';
import MosaicPanel from 'src/app/TopListsMosaic/layout/MosaicPanel';
import MosaicPanelBody from 'src/app/TopListsMosaic/layout/MosaicPanelBody';
import SmallFilterWindow from 'src/app/components/filterContainers/SmallFilterWindow';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import { ExpandIcon, MarketOpenIcon, RefreshIcon } from 'src/theme/EdgeIcons';
import AutosavedChart from 'src/app/ChartingLibrary/AutosavedChart';
import { MosaicWindowContext } from 'react-mosaic-component';
import { useDeepCompareEffect } from 'src/hooks/useDeepCompare';
import DateLinkDropdown from 'src/app/TopListsMosaic/TopListChart/DateLinkDropdown';
import {
  alpha,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  addBusinessDays,
  getCurrentTradingDay,
  getMostRecentTradingDay,
  formatMarketTime, parseAssumeMarketTime
} from 'src/utils/datetime/date-fns.tz';


const useStyles = makeStyles(() => ({
  root: {},
  filterWindowFormControl: {
    padding: '7px 0',
  },
  chartPanel: {
    borderRadius: '0 !important'
  },
  chartPortalContainer: {
    height: '100%',
    display: 'flex',
    flex: 1,
  },
}));


/**
 * There are 3 possible dates the user can switch between.
 * @see CHART_DATE_INITIALIZATION_TYPES
 *
 * @param {keyof CHART_DATE_INITIALIZATION_TYPES} dateInitializationType
 * @param {string} historicalDate
 * @param {string} specificallySelectedDay
 * @return {string|null}
 */
const decideSelectedDate = (dateInitializationType, historicalDate, specificallySelectedDay) => {
  if (dateInitializationType === CHART_DATE_INITIALIZATION_TYPES.TODAY) {
    return null;
  } else if (dateInitializationType === CHART_DATE_INITIALIZATION_TYPES.LINKED_DATE) {
    return historicalDate;
  } else if (dateInitializationType === CHART_DATE_INITIALIZATION_TYPES.SELECT_DATE) {
    return specificallySelectedDay;
  }
};


const getIsToday = (selectedDate, mostRecentTradingDayString, currentTradingDayString) => {
  if (!selectedDate && mostRecentTradingDayString === currentTradingDayString) {
    return true;
  } else if (selectedDate && selectedDate === currentTradingDayString) {
    return true;
  }
  return false;
}


function TopListChart({ className }) {
  const classes = useStyles();
  const { componentId, layoutId } = useContext(LayoutContext);
  const { mosaicWindowActions } = useContext(MosaicWindowContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { linkedData, dispatchUpdateLinkedData } = useToplistLinkedValues();
  /** @type {{string, string}} */
  const { ticker = 'AAPL', historicalDate } = linkedData;

  const {
    selectedTemplateId = DEFAULT_TEMPLATE_ID,
    interval,
    /** @type {CHART_DATE_INITIALIZATION_TYPES} */
    dateInitializationType,
    /** @type {string} */
    specificallySelectedDay = null
  } = useSelector(selectComponent(componentId, layoutId));

  const [selectedDate, setSelectedDate] = useState(() => decideSelectedDate(dateInitializationType, historicalDate, specificallySelectedDay));

  const mostRecentTradingDayString = formatMarketTime(getMostRecentTradingDay(), 'yyyy-MM-dd');
  const currentTradingDayString = formatMarketTime(getCurrentTradingDay(), 'yyyy-MM-dd');
  // by default, selectedDate is null, and the chart automatically requests most recent data. Even so, we still want to display the date in that case.
  let visualSelectedDate = selectedDate || mostRecentTradingDayString;

  /** 
   * If the chart shows the most recent data available. May not be "today" on the weekend or holiday
   **/
  const isViewingMostRecentData = !selectedDate || selectedDate === formatMarketTime(getMostRecentTradingDay(), 'yyyy-MM-dd');

  /**
   * If the chart is showing live data, and its actually Today's data
   **/
  let isToday = getIsToday(selectedDate, mostRecentTradingDayString, currentTradingDayString);

  const [remountChart, setRemountChart] = useState(0);
  const mosaicPath = mosaicWindowActions.getPath();


  useDeepCompareEffect(() => {
    setRemountChart(remountChart + 1);
  }, [mosaicPath]);


  useEffect(() => {
    if (dateInitializationType === CHART_DATE_INITIALIZATION_TYPES.LINKED_DATE) {
      setSelectedDate(historicalDate);
    }
  }, [historicalDate]);


  useEffect(() => {
    if (ticker) {
      const resolvedDate = decideSelectedDate(dateInitializationType, historicalDate, specificallySelectedDay);
      setSelectedDate(resolvedDate);
    }
  }, [ticker]);


  const dispatchTicker = useCallback((ticker) => {
    if (ticker) {
      dispatchUpdateLinkedData({ ticker });
    }
  }, [dispatchUpdateLinkedData]);


  const handleReset = useCallback(() => {
    setSelectedDate(null);
    if (dateInitializationType === CHART_DATE_INITIALIZATION_TYPES.LINKED_DATE) {
      dispatchUpdateLinkedData({ historicalDate: null });
    }
  }, [dateInitializationType, dispatchUpdateLinkedData]);


  const handleDayGoButtonClick = useCallback(() => {
    const resolvedDate = decideSelectedDate(dateInitializationType, historicalDate, specificallySelectedDay);
    setSelectedDate(resolvedDate);
  }, [dateInitializationType, historicalDate, specificallySelectedDay]);


  const handleNextDay = useCallback(() => {
    if (!selectedDate) return;
    const date = parseAssumeMarketTime(selectedDate, 'yyyy-MM-dd');
    setSelectedDate(formatMarketTime(addBusinessDays(date, 1), 'yyyy-MM-dd'));
  }, [selectedDate]);


  const handleDatePickerAccept = useCallback((datestring) => {
    dispatch(updateComponent(componentId, layoutId, {
      specificallySelectedDay: datestring === formatMarketTime(getMostRecentTradingDay(), 'yyyy-MM-dd') ? null : datestring
    }));
  }, [componentId, layoutId]);


  const handleDateInitializationTypeChange = useCallback((event, value) => {
    if (!value || dateInitializationType === value) return;
    dispatch(updateComponent(componentId, layoutId, { dateInitializationType: value }));
  }, [componentId, layoutId, dateInitializationType]);


  const handleTemplateSelect = useCallback((templateId) => {
    dispatch(updateComponent(componentId, layoutId, { selectedTemplateId: templateId }));
  }, [componentId, layoutId]);


  const handleLayoutSelect = useCallback((chartLayoutId) => {
    dispatch(updateComponent(componentId, layoutId, { selectedLayoutId: chartLayoutId }));
  }, [componentId, layoutId]);


  const handleIntervalChange = useCallback((interval) => {
    dispatch(updateComponent(componentId, layoutId, { interval }));
  }, [componentId, layoutId]);


  return (
    <MosaicPanel
      className={clsx(className, classes.root)}
      variant={isViewingMostRecentData ? null : 'yellow'}
    >
      <MosaicPanelHeader
        tickerSearchValue={ticker}
        onTickerSearchSubmit={dispatchTicker}
        title={' '}
        titleSuppliment={isToday ? 'today' : visualSelectedDate}
        titleSupplimentColor={alpha(isToday ? theme.palette.text.positive : theme.palette.text.secondary, .7)}
        loading={false}
      >
        <IntercomArticleButton
          // articleId={INTERCOM_ARTICLES?.toplist?.components?.[COMPONENT_TYPES.CHART]}
          articleSlug={INTERCOM_SLUGS?.toplist?.components?.chart}
        />
        {selectedDate && (
          <>
            <PanelIconButton
              Icon={RefreshIcon}
              onClick={handleReset}
              text="Reset"
              shouldHideIconText={true}
            />
            {!isViewingMostRecentData && (
              <PanelIconButton
                Icon={ExpandIcon}
                onClick={handleNextDay}
                text="Next Day"
                shouldHideIconText={true}
              />
            )}
          </>
        )}
        <SmallFilterWindow
          iconText="Date"
          Icon={MarketOpenIcon}
          iconColor={isViewingMostRecentData ? theme.palette.text.primary : theme.palette.historical.icon}
          shouldHideIconText={true}
          // popoverTitle="Change Date"
          popoverMinWidth={392}
        >
          <DateLinkDropdown
            toggleValue={dateInitializationType}
            selectedDay={specificallySelectedDay}
            onToggleChange={handleDateInitializationTypeChange}
            onDatePickerAccept={handleDatePickerAccept}
            onGoButtonClick={handleDayGoButtonClick}
          />
        </SmallFilterWindow>
      </MosaicPanelHeader>
      <MosaicPanelBody
        loading={false}
        className={classes.chartPortalContainer}
      >
        <AutosavedChart
          key={remountChart <= 1 ? 0 : remountChart}
          className={classes.chartPanel}
          tickerId={ticker}
          componentId={componentId}
          selectedLayoutId={DEFAULT_LAYOUT_ID}
          selectedTemplateId={selectedTemplateId}
          clientId={CLIENT_IDS.GLOBAL_V1}
          fromDate={selectedDate || null}
          interval={interval}
          isRealtime
          onTemplateSelect={handleTemplateSelect}
          onLayoutSelect={handleLayoutSelect}
          onIntervalChange={handleIntervalChange}
        />
      </MosaicPanelBody>
    </MosaicPanel>
  );
}

export default TopListChart;

