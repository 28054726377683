import React, { useRef, useEffect, useMemo, useContext, useState, useCallback } from 'react';
import clsx from 'clsx';
import LayoutContext from '../layout/LayoutContext';
import {
  formatMarketTime,
  getCurrentTradingDay,
  getMostRecentTradingDay,
  getOldestAllowedTopListDate,
} from 'src/utils/datetime/date-fns.tz';
import IntercomArticleButton, { INTERCOM_ARTICLES } from 'src/app/components/intercom/IntercomArticleButton';
import { useSelector, useDispatch } from 'react-redux';
import { useSyncronizedTimer } from 'src/context/SyncronizedTimerContext';
import useNewsContextMenu from 'src/app/components/grid/contextMenu/useNewsContextMenu';
import { useDeepCompareEffect } from 'src/hooks/useDeepCompare';
import useToplistLinkedValues, {
  LINK_DISPATCH_RULES,
  LINK_INITIALIZATION_RULES
} from 'src/hooks/useToplistLinkedValues';
import useCategoryModalStyle from './styles/useCategoryModalStyle';
import { AgGridReact } from '@ag-grid-community/react';
import useUserPlanPermissions from 'src/hooks/useUserPlanPermissions';
import {
  GRID_COLUMNS
} from 'src/app/components/grid/topListNews/columns/columnDefs';
import {
  buildGridColumns,
} from 'src/app/components/grid/buildColumns';
import { PROFILE_CONFIG } from 'src/redux/layout/topListLayoutSchema';
import { CB_ITEM_NAMES, newsCategoriesChecklistItems } from 'src/app/components/grid/topListNews/columns/newsConstants';
import { handleKeyboardRowNavigation } from 'src/utils/agGridFunctions';
import { selectComponent, selectProfileList } from 'src/redux/layout/topListLayoutSelectors';
import {
  updateComponent,
  updateNewsColumnProfiles,
  updateScannerFilterProfiles
} from 'src/redux/layout/topListLayoutActions';
import useRealtimeMappedExpressions from 'src/app/components/grid/topListScanner/columns/useRealtimeMappedExpressions';
import useFilterRelevantExpressions from 'src/app/slicedForm/shared/hooks/useFilterRelevantExpressions';
import OverlayGrid from './OverlayGrid';
import NewsTickerGroupCellRenderer from 'src/app/components/grid/cellRenderers/NewsTickerGroupCellRenderer';
import NewsHeadlineCellRenderer from 'src/app/components/grid/cellRenderers/NewsHeadlineCellRenderer';
import AnimateChangeCellRenderer from 'src/app/components/grid/cellRenderers/AnimateChangeCellRenderer';
import LoadingCellSkeleton from 'src/app/components/grid/cellRenderers/LoadingCellSkeleton';
import NewsDataSource, { GRID_ASYNC_TRANSACTION_WAIT_MILLIS } from 'src/app/components/grid/topListNews/dataSource/dataSource';
import FilterModal from 'src/app/components/filterContainers/FilterModal';
import NestedCheckboxFilterModal from 'src/app/components/filterContainers/NestedCheckboxFilterModal';
import NewsSlicedColumnsForm from './forms/NewsSlicedColumnsForm';
import SlicedFiltersForm from '../TopListScanner/forms/SlicedFiltersForm'
import MosaicPanel from 'src/app/TopListsMosaic/layout/MosaicPanel';
import MosaicPanelHeader from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/MosaicPanelHeader';
import MosaicPanelBody from 'src/app/TopListsMosaic/layout/MosaicPanelBody';
import SmallFilterWindow from 'src/app/components/filterContainers/SmallFilterWindow';
import FilterKeywordSearchMultiChip from 'src/app/components/filterElements/FilterKeywordSearchMultiChip';
import { NewsSourceIcon, SearchIcon } from 'src/theme/EdgeIcons';
import {
  deserializeCategoryFilters,
  serializeCategoryFilters
} from 'src/app/components/grid/topListNews/columns/filterStateFunctions';
import {
  useTheme,
  makeStyles
} from '@material-ui/core';
import CompactNumberCell from 'src/app/components/grid/cellRenderers/CompactNumberCell';
import useIsMountedRef from 'src/hooks/useIsMountedRef';


const gridComponents = {
  'NewsTickerGroupCellRenderer': NewsTickerGroupCellRenderer,
  'NewsHeadlineCellRenderer': NewsHeadlineCellRenderer,
  'animateChangeCellRenderer': AnimateChangeCellRenderer,
  'compactNumberCellRenderer': CompactNumberCell
};


const gridSortOrder = ['asc', 'desc'];


const useStyles = makeStyles(() => ({
  root: {},
  gridContainer: {
    flex: 1,
    '& .ag-root-wrapper': {
      borderRadius: '0 !important'
    }
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 400,
    backgroundColor: 'white'
  }
}));




function TopListNews({ className }) {
  const { componentId, layoutId } = useContext(LayoutContext);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [expressions, expressionPayload] = useRealtimeMappedExpressions()
  const {
    categories,
    searchKeywords = [],
    cellFlashDisabled = false,
    [PROFILE_CONFIG.NEWS_COLUMNS.idKey]: columnProfileId,
    [PROFILE_CONFIG.SCANNER_FILTERS.idKey]: filterProfileId,
  } = useSelector(selectComponent(componentId, layoutId));
  const permissions = useUserPlanPermissions(['scanner_copy_paste', 'history_max_years']);
  const {
    linkedData,
    dispatchUpdateLinkedData,
    clearLinkedDataForComponent,
  } = useToplistLinkedValues({ initializationRule: LINK_INITIALIZATION_RULES.TIMESTAMP });
  const { ticker, updatedAt: linkedDataUpdatedAt } = linkedData;
  const today = formatMarketTime(getCurrentTradingDay(), 'yyyy-MM-dd');

  const mostRecentTradingDayString = formatMarketTime(getMostRecentTradingDay(), 'yyyy-MM-dd');
  const oldestDateAvailable = useMemo(() => getOldestAllowedTopListDate(permissions.history_max_years), [mostRecentTradingDayString]);

  const columnProfilesList = useSelector(selectProfileList(PROFILE_CONFIG.NEWS_COLUMNS.listKey));
  const filterProfilesList = useSelector(selectProfileList(PROFILE_CONFIG.SCANNER_FILTERS.listKey));
  const columnProfile = columnProfilesList.find(p => p.id === columnProfileId);
  const filterProfile = filterProfilesList.find(p => p.id === filterProfileId);
  const filterValues = filterProfile?.filters || {};

  const relevantExpressions = useFilterRelevantExpressions(expressions, { columnProfile, filterProfile });

  const [clickedRscoreRowNode, setClickedRscoreRowNode] = useState(null);

  const [isFetching, setIsFetching] = useState(true);
  const [lastDate, setLastDate] = useState(null);

  const isMounted = useIsMountedRef();
  const gridRef = useRef();
  const gridReadyRef = useRef(false);

  const setCellFlashDisabled = useCallback((newValue) => {
    dispatch(updateComponent(componentId, layoutId, {
      cellFlashDisabled: !!newValue
    }));
  }, [componentId, layoutId])

  const makeContextMenu = useNewsContextMenu({ setCellFlashDisabled });

  const memoizedDataSource = useMemo(() => {
    return new NewsDataSource(componentId);
  }, []);


  useEffect(() => {
    const CHANNEL = process.env.REACT_APP_STOCK_NEWS_ABLY_CHANNEL;
    if (!CHANNEL) {
      throw Error('STOCK_NEWS_ABLY_CHANNEL env variable invalid');
    }
    if (isMounted.current) {
      memoizedDataSource.register();
      memoizedDataSource.openWebsocket(CHANNEL, gridRef, isMounted);
    }
    return () => {
      memoizedDataSource.unregister();
      memoizedDataSource.closeWebsocket(CHANNEL);
    };
  }, [memoizedDataSource]);


  const resetDataSource = useCallback(() => {
    if (!isMounted.current) return;
    setIsFetching(true);
    setLastDate(null);
    if (!gridRef.current) return;
    memoizedDataSource.resetCache();
    gridRef.current?.api.setServerSideDatasource(memoizedDataSource);
  }, [memoizedDataSource]);

  // categories is local, we can do a normal equality check
  useEffect(() => {
    if (memoizedDataSource.initialRequestComplete) {
      setTimeout(() => {
        resetDataSource()
      }, 0);
    }
  }, [categories]);

  // These objects keep the same instance (shared between components), meaning we need deep equality
  useDeepCompareEffect(() => {
    if (memoizedDataSource.initialRequestComplete) {
      setTimeout(() => {
        resetDataSource()
      }, 0);
    }
  }, [searchKeywords, columnProfile.columns, filterValues, relevantExpressions]);

  // Normally we want to wait until initialRequestComplete to avoid double fetches, but the user could be changing tickers rapidly
  useEffect(() => {
    if (gridRef.current && gridReadyRef.current) {
      setTimeout(() => {
        resetDataSource()
      }, 0);
    }
  }, [ticker]);

  // Update quotes inside grid
  useSyncronizedTimer(() => {
    if (gridRef.current && memoizedDataSource) {
      try {
        setTimeout(() => memoizedDataSource.refetchQuotes(gridRef, isMounted), 0);
      } catch (err) {
        console.error(err);
      }
    }
  }, 2);

  // When context changes, AG-Grid doesn't necissarily redraw the rows,
  // meaning our in-cell event handlers become stale. Refresh them manually
  useEffect(() => {
    if (!gridRef?.current?.api) return;
    setTimeout(() => {
      gridRef?.current?.api?.refreshCells({
        force: true,
        columns: ['ticker', 'ag-Grid-AutoColumn']
      });
    }, 0)
  }, [dispatchUpdateLinkedData])


  const newsColumns = useMemo(() => {
    return buildGridColumns(columnProfile.columns, GRID_COLUMNS, relevantExpressions, { sortable: false });
  }, [columnProfile, relevantExpressions]);

  const handleKeywordChange = (newKeywords) => {
    dispatch(updateComponent(componentId, layoutId, {
      searchKeywords: newKeywords
    }));
  };

  const handleColumnProfileSubmit = useCallback(({ expressions, ...profile }) => {
    dispatch(updateNewsColumnProfiles(profile, layoutId, componentId, expressionPayload(expressions)));
  }, [layoutId, componentId]);


  const handleFilterProfileSubmit = useCallback(({ expressions, ...profile }) => {
    dispatch(updateScannerFilterProfiles(profile, layoutId, componentId, expressionPayload(expressions)));
  }, [layoutId, componentId]);


  /** Fired when clicking on ticker name directly, opens submenu **/
  const handleSelectNewsTicker = useCallback((rowNodeData) => {
    if (rowNodeData?.ticker && rowNodeData?.datetime) {
      // Don't send historial date if its most recent trading day
      const historicalDate = rowNodeData.datetime.split(' ')[0];
      const resolvedDate = historicalDate === mostRecentTradingDayString ? false : historicalDate;

      dispatchUpdateLinkedData({
        ticker: rowNodeData.ticker,
        historicalDate: resolvedDate
      });
    }
  }, [dispatchUpdateLinkedData, mostRecentTradingDayString]);


  /** Fired when clicking anywhere in row **/
  const handleNewsRowClicked = useCallback((rowNode, e) => {
    const rowNodeData = rowNode?.data || {};

    // when we're already on a ticker, we don't want to IGNORE_SELF.
    // This is because we're already in the context of the ticker, dispatching
    // the same ticker to ourselveses changes nothing, except it adds a flag 
    // to the reducer which in turn means refreshing the page will DELETE the 
    // ticker state from component state.
    //
    // That is not what we want, the ticker is visible and so should remain visible.
    //
    // TODO: I want a real compsci solution to this whole business. Maybe using the 
    // concept of "registers".
    //
    // Another idea is to copy link state to each component. Bad for memory, but has
    // way more control.
    const isInnerNews = Boolean(rowNode?.context?.ticker);

    if (rowNode?.event?.srcElement.classList.contains('prevent-row-click')) {
      return;
    }

    if (rowNodeData?.ticker && rowNodeData?.datetime) {
      const historicalDate = rowNodeData.datetime.split(' ')[0];
      const resolvedDate = historicalDate === mostRecentTradingDayString ? false : historicalDate;

      dispatchUpdateLinkedData(
        {
          ticker: rowNodeData.ticker,
          historicalDate: resolvedDate
        },
        isInnerNews ? null : LINK_DISPATCH_RULES.IGNORE_SELF
      );
    }
  }, [dispatchUpdateLinkedData, mostRecentTradingDayString]);


  const handleCategoriesSubmit = useCallback((flatCheckboxStates) => {
    const serializedCategories = serializeCategoryFilters(flatCheckboxStates, newsCategoriesChecklistItems);
    dispatch(updateComponent(componentId, layoutId, {
      categories: serializedCategories
    }));
  }, []);

  const categoriesInitialState = useMemo(() => {
    return deserializeCategoryFilters(categories, newsCategoriesChecklistItems);
  }, [categories]);

  const handleRscoreClick = useCallback((rowNodeData) => {
    setIsFetching(true);
    setClickedRscoreRowNode(rowNodeData);
  }, []);


  const loadingCellRendererSelector = useCallback((params) => {
    if (params?.node?.level > 0) {
      return { component: () => <span /> };
    }
    return { component: LoadingCellSkeleton };
  }, []);

  const getRowId = useCallback(({ data }) => {
    return `${data.news_id}_${data.ticker}`;
  }, []);

  const getTreeDataPath = useCallback((data) => {
    return data.ticker;
  }, []);

  const getGroupChildCount = useCallback((data) => {
    return data.childCount || 0;
  }, []);

  const isServerSideGroup = useCallback((data) => {
    return data.childCount > 0;
  }, []);

  const getServerSideGroupKey = useCallback((data) => {
    return data.news_id;
  }, []);


  const getRowHeight = useCallback((params) => {
    return params.node.level === 0 ? 50 : 24;
  }, []);


  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Ticker',
      field: 'ticker',
      width: 67,
      resizable: true,
      editable: false,
      sortable: false,
      suppressMenu: true,
      cellRenderer: 'NewsTickerGroupCellRenderer',
      cellClass: (params) => {
        const classes = ['ag-ett-flex-center'];
        // check if ticker exists in AG context, not just on component state.
        // Otherwise, the CSS is applied before the grid updates.
        // if (params?.data?.datetime && params?.context?.ticker) {
        if (params?.data?.datetime) {
          if (params.data.datetime.split(' ')[0] === today && !isFetching) {
            classes.push('ag-ett-red-notification-cell');
          }
        }
        return classes;
      },
      cellRendererParams: {
        innerRenderer: (params) => {
          return params.data.ticker;
        },
      },
    };
  }, [today, ticker, isFetching]);


  const onGridReady = useCallback(({ api }) => {
    setIsFetching(true);
    api.setServerSideDatasource(memoizedDataSource);
    gridReadyRef.current = true;
  }, []);


  const popupParent = useMemo(() => {
    return document.querySelector('body');
  }, []);


  return (
    <MosaicPanel className={clsx(className, classes.root)}>
      <MosaicPanelHeader
        loading={isFetching}
        tickerSearchValue={ticker}
        onTickerSearchSubmit={(symbol) => dispatchUpdateLinkedData({ ticker: symbol })}
        showClearButton
        tickerSearchPlaceholder="ALL"
        onTickerSearchClear={clearLinkedDataForComponent}
      >
        <IntercomArticleButton
          // articleId={INTERCOM_ARTICLES?.toplist?.components?.[COMPONENT_TYPES.NEWS]}
          articleSlug={INTERCOM_ARTICLES?.toplist?.components?.news}
        />
        <SmallFilterWindow
          Icon={SearchIcon}
          iconText={'Search'}
          iconColor={searchKeywords.length ? theme.palette.primary.main : theme.palette.text.primary}
          shouldHideIconText={true}
          popoverTitle="Search Headlines"
          popoverMinWidth={425}
        >
          <FilterKeywordSearchMultiChip
            keywords={searchKeywords}
            onKeywordChange={handleKeywordChange}
          />
        </SmallFilterWindow>
        <NewsSlicedColumnsForm
          profiles={columnProfilesList}
          activeProfile={columnProfileId}
          expressions={expressions}
          onSubmit={handleColumnProfileSubmit}
        />
        <SlicedFiltersForm
          profiles={filterProfilesList}
          activeProfile={filterProfileId}
          expressions={expressions}
          onSubmit={handleFilterProfileSubmit}
          oldestDateAvailable={oldestDateAvailable}
        />
        <FilterModal
          Icon={NewsSourceIcon}
          modalSize="md"
          iconText={'Sources & Topics'}
          shouldHideIconText={true}
          width={600}
          height={1500}
          modalTitle="Categories"
          shouldRenderProps
          keepMounted={false}
        >
          {(handleClose) => (
            <NestedCheckboxFilterModal
              items={newsCategoriesChecklistItems}
              disabledItemKeys={ticker ? [CB_ITEM_NAMES.TOPICS_LABEL] : []}
              initialState={categoriesInitialState}
              useInstanceStyles={useCategoryModalStyle}
              closeModal={handleClose}
              onSubmit={handleCategoriesSubmit}
            />
          )}
        </FilterModal>
      </MosaicPanelHeader>
      <MosaicPanelBody
        loading={isFetching}
        className={clsx(
          classes.gridContainer,
          'ag-theme-ett',
          'ag-theme-font-roboto',
          'ag-theme-no-row-selection',
          `ag-grid-${componentId}`,
          ticker && 'ag-grid-prevent-cell-flash'
        )
        }>
        {clickedRscoreRowNode &&
          <OverlayGrid
            parentRowNode={clickedRscoreRowNode}
            parentDataSource={memoizedDataSource}
            onClose={() => setClickedRscoreRowNode(null)}
            setIsFetching={setIsFetching}
          />
        }
        <AgGridReact
          ref={gridRef}
          context={{
            ticker,
            componentId,
            columnProfile,
            filterProfile,
            categories,
            lastDate,
            setLastDate,
            searchKeywords,
            setIsFetching,
            handleRscoreClick,
            handleSelectNewsTicker,
            expressions,
            cellFlashDisabled,
            isMountedRef: isMounted
          }}
          onRowClicked={handleNewsRowClicked}
          components={gridComponents}
          enableSorting={false}
          treeData={true}
          isServerSideGroup={isServerSideGroup}
          getServerSideGroupKey={getServerSideGroupKey}
          isServerSideGroupOpenByDefault={false}
          getChildCount={getGroupChildCount}
          getDataPath={getTreeDataPath}
          columnDefs={newsColumns}
          autoGroupColumnDef={autoGroupColumnDef}
          rowSelection={'single'}
          navigateToNextCell={handleKeyboardRowNavigation}
          suppressMultiSort={true}
          animateRows={true}
          headerHeight={25}
          popupParent={popupParent}
          getContextMenuItems={makeContextMenu}
          getRowId={getRowId}
          sortingOrder={gridSortOrder}
          serverSideInitialRowCount={1}
          cacheBlockSize={30}
          maxConcurrentDatasourceRequests={1}
          getRowHeight={getRowHeight}
          blockLoadDebounceMillis={100}
          rowModelType="serverSide"
          onGridReady={onGridReady}
          loadingCellRendererSelector={loadingCellRendererSelector}
          asyncTransactionWaitMillis={GRID_ASYNC_TRANSACTION_WAIT_MILLIS}
        />
      </MosaicPanelBody>
    </MosaicPanel>
  );
}


export default TopListNews;
