/** 
 * @typedef {Object} ChangesetResponse
 * @property {number} revision
 * @property {boolean} success
 */

/**
 * @typedef {Object} LayoutResponse
 * @property {number} revision
 * @property {object} data
 */

/**
 * @typedef {Object} StoredValue
 * @property {Object} data
 * @property {number} revision
 * @property {number} writtenAt
 */

/**
 * @typedef {Object} MessageEvent
 * @property {string} data
 * @property {string} origin
 * @property {string} lastEventId
 * @property {BroadcastChannel} source
 * @property {MessagePort[]} ports
 */

/**
 * @callback RevisionRuleItemCallback
 * When a callback operates on a single record
 * @param {object} args
 * @param {string[]} args.pathParts - The path to the value, like [profiles, scannerFilter, id123]
 * @param {object} args.record - The value at that path.
 * @param {object|array} args.parent - The parent object or array of the value.
 * @param {object} args.state - Entire state object.
 **/

/**
 * @typedef {Object} RevisionRule
 * Defines the syncing behavior of our reducer. This tells us:
 *  - How to map records going from redux into db/localstorage
 *  - The ID/sortKey of the record for DDB
 *  - Specifics on how to build the Revision changeset for DDB
 * @property {RevisionRuleItemCallback} [getId] - The ID of the individual record. If not defined, then this slice of state will not be considered for syncing to DB
 * @property {RevisionRuleItemCallback} [getRecordType] - The recordType in dynamoDB, usually the full path. If not defined, then this slice of state will not be considered for syncing to DB
 * @property {RevisionRuleItemCallback} [filterRecord] - Remove record before sending to DB/localstorage (boolean)
 * @property {RevisionRuleItemCallback} [mapRecord] - Modify a single record
 * @property {boolean} [add] - If true, an 'add' action causes a revision bump
 * @property {boolean} [delete] - If true, a 'delete' action causes a revision bump
 * @property {boolean} [put] - If true, a 'put' (update) action causes a revision bump
 */


/**
 * @typedef {Object} ReadRule
 * Like RevisionRule, but specifies mapping when data is READ from a datasource
 * As a result, it does not build changesets, or cause network requests etc. Just mappings.
 * NOTE: Must be seperated, because mappings often must happen on the entire collection, not just a single record.
 *
 * @property {RevisionRuleItemCallback} [mapRecord] - Modify a single record
 * @property {RevisionRuleItemCallback} [filterRecord] - Remove a single record
 **/


/**
 * @typedef {Object} ChangeItem
 * @property {keyof ACTIONS} action
 * @property {string} id
 * @property {string} type
 * @property {Object} [data]
 */




export const INIT_SOURCE = {
  storage: 'storage',
  database: 'database',
  initialState: 'initialState',
}

export const ACTIONS = {
  put: 'put',
  add: 'add',
  delete: 'delete',
  // UPDATE: 'UPDATE' 
}

