
/** Allowed functions **/
export const FUNCTIONS = {
  GREATEST: {
    func: args => Math.max(...args),
    arity: -1, // any
  },
  LEAST: {
    func: args => Math.min(...args),
    arity: -1, // any
  },
  ABS: {
    func: args => Math.abs(args[0]),
    arity: 1
  }
}

/** Not used in the parser, just for instructions **/
export const OPERATIONS = ["+", "-", "*", "/", "^", "(", ")"]

export const FUNCTION_LABELS = {
  GREATEST: {
    description: "Returns the greatest value",
    example: "GREATEST(1, 3, 2)",
    answer: 3
  },
  LEAST: {
    description: "Returns the least value",
    example: "LEAST(1, 3, 2)",
    answer: 1
  },
  ABS: {
    description: "Returns the absolute value",
    example: "ABS(-5)",
    answer: 5
  }
}

/** Allowed constants **/
const CONSTANTS = {
  // PI: Math.PI,
  // E: Math.E
};


/** Replace placeholders with this value, in order to evaluate **/
const PLACEHOLDER_VALUE = 10; // [[A]] -> 10


/**
  * Utility function to check for placeholders in the CST.
  * Used to ignore divide-by-zero errors when a placeholder is involved.
  * 
  * 10 / 0          : should throw error
  * 10 / [[A]] - 10 : should not throw error
  **/
const containsPlaceholder = (node) => {
  if (node.ctorName === 'placeholder') {
    return true;
  }
  for (let child of node.children) {
    if (containsPlaceholder(child)) {
      return true;
    }
  }
  return false;
}


/** The grammar for the parser **/
export const source = String.raw`
  Arithmetic {
    Exp
      = AddExp

    AddExp
      = AddExp "+" MulExp  -- plus
      | AddExp "-" MulExp  -- minus
      | MulExp

    MulExp
      = MulExp "*" ExpExp  -- times
      | MulExp "/" ExpExp  -- divide
      | ExpExp

    ExpExp
      = PriExp "^" ExpExp  -- power
      | PriExp

    PriExp
      = "(" Exp ")"  -- paren
      | "+" PriExp   -- pos
      | "-" PriExp   -- neg
      | FunctionCall -- greatest
      | placeholder  -- columns
      | null         -- null
      | characters
      | number

    Args (a list of arguments)
      = ListOf<Exp, ",">

    placeholder (a @Column)
      = "[[" upper "]]"

    null
      = "NULL"

    FunctionCall (a Function)
      = functionName "(" Args ")"

    functionName (a function name)
      = letter alnum*

    characters
      = letter alnum*

    number  (a number)
      = digit* "." digit+  -- fract
      | digit+             -- whole
  }`;


export class ArithmaticPermissionsError extends Error {
  constructor(message, zero_division = false) {
    super(message);
    this.name = 'PermissionsError';
    this.zero_division = zero_division;
  }
}



/** 
 * The evaluator for the parser 
 * @param {InterpreterState} state - The state object to store metadata during evaluation.
 * @param {Object} options - The options object to configure the interpreter.
 * @param {number} options.maxDepth - The maximum depth of the expression tree.
 * @param {number} options.maxArguments - The maximum number of arguments a function can have.
 * @param {number} options.maxPlaceholders - The maximum number of unique column placeholders.
 * @param {boolean} options.allowNullToken - Whether to allow the NULL token during evaluation.
 **/
export const makeInterpreter = (
  state,
  {
    maxDepth,
    maxArguments,
    maxPlaceholders,
  }
) => {

  const markDepth = () => {
    if (state.get('depth', 0) > maxDepth) {
      throw new ArithmaticPermissionsError("Statement too complex, please simplify.");
    }
    state.count('depth');
  }

  return {
    Exp(e) {
      return e.interpret();  // Note that operations are accessed as methods on the CST nodes.
    },
    AddExp(e) {
      return e.interpret();
    },
    AddExp_plus(x, _, y) {
      markDepth();
      return x.interpret() + y.interpret();
    },
    AddExp_minus(x, _, y) {
      markDepth();
      return x.interpret() - y.interpret();
    },
    MulExp(e) {
      return e.interpret();
    },
    MulExp_times(x, _, y) {
      markDepth();
      return x.interpret() * y.interpret();
    },
    MulExp_divide(x, _, y) {
      markDepth();
      const numerator = x.interpret();
      const denominator = y.interpret();

      if (denominator === 0) {
        if (containsPlaceholder(y)) {
          // Avoid divide-by-zero when placeholder is involved.
          return x.interpret / 1;
        } else {
          throw new ArithmaticPermissionsError("Cannot divide by Zero");
        }
      }
      return numerator / denominator;
    },
    ExpExp(e) {
      return e.interpret();
    },
    ExpExp_power(x, _, y) {
      markDepth();
      return Math.pow(x.interpret(), y.interpret());
    },
    PriExp(e) { return e.interpret(); },
    PriExp_paren(_l, e, _r) { return e.interpret(); },
    PriExp_pos(_, e) { return e.interpret(); },
    PriExp_neg(_, e) { return -e.interpret(); },
    FunctionCall(funcName, _l, args, _r) {
      markDepth();
      const functionName = funcName.sourceString.toUpperCase();

      if (!FUNCTIONS[functionName]) {
        throw new ArithmaticPermissionsError(`Unknown function "${functionName}"`);
      }

      const { func, arity } = FUNCTIONS[functionName];

      const argValues = args.interpret();

      if (argValues.length > maxArguments) {
        throw new ArithmaticPermissionsError(`Function "${functionName}" has too many arguments (${maxArguments} max).`);
      }

      if (arity > -1 && argValues.length !== arity) {
        throw new ArithmaticPermissionsError(`Function "${functionName}" must have ${arity} arguments.`);
      }

      return func(argValues);
    },
    Args(list) {
      return list.asIteration().interpret();
    },
    placeholder(_l, col, _r) {
      if (!state.get('allowColumnPlaceholders', true)) {
        throw new ArithmaticPermissionsError("Column placeholders not allowed.");
      }
      if (state.get('placeholders', 0) > maxPlaceholders) {
        throw new ArithmaticPermissionsError(`Exceeded maximum number of columns (${maxPlaceholders} max).`);
      }
      state.count('placeholders');

      return PLACEHOLDER_VALUE;
    },
    characters(a, b) {
      const upperName = this.sourceString.toUpperCase();
      console.log(upperName, FUNCTIONS[upperName]);
      if (FUNCTIONS[upperName]) {
        throw new ArithmaticPermissionsError(`Functions must be called with parentheses, like: ${FUNCTION_LABELS[upperName].example}`);
      }
      throw new ArithmaticPermissionsError(`Unknown symbol "${this.sourceString}"`)
    },
    null(_) {
      if (!state.get('allowNullToken', false)) {
        throw new ArithmaticPermissionsError("NULL not allowed.");
      }
      return null;
    },
    number(_) {
      return parseFloat(this.sourceString);
    },
    _iter(...children) {
      return children.map(c => c.interpret());
    }
  }
}





